$contents-width: 960px;
$bg-sub-color: #f1f8fd;
$shadow-style: 0 2px 12px 6px rgba(0, 0, 0, 0.04);
$register-color: #ffae28;
$inquiry-color: #505e74;

.faq {
  background: #fff;

  @media #{$sp} {
    padding-top: 24px;
  }

  .faq-header {
    background: url(../images/guideline/bg_header_pc.svg) #11b3f3 no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 280px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media #{$sp} {
      padding-top: 64px;
      background: url(../images/guideline/bg_header_mobile.svg) #11b3f3 no-repeat;
      background-size: cover;
      background-position: center;
      padding: 24px 12px;
      height: auto;
    }

    h1 {
      color: #fff;
      font-size: 32px;
      margin-bottom: 16px;
      line-height: 1.2;

      @media #{$sp} {
        line-height: 1.3;
        font-size: 22px;
        margin: 0 16px 8px;
        span {
          font-size: 14px;
        }
      }
    }

    .description {
      color: #fff;
      font-size: 16px;
      margin-bottom: 32px;
      @media #{$sp} {
        font-size: 14px;
        margin-bottom: 24px;
      }
    }

    .input-control {
      position: relative;
      max-width: 480px;
      min-height: 48px;
      line-height: 48px;
      @media #{$sp} {
        max-width: 300px;
        min-height: 40px;
        line-height: 40px;
      }
      input {
        height: 48px;
        border: 0;
        border-radius: 50px;
        padding: 0px 24px 0 43px;
        text-align: left;
        @media #{$sp} {
          font-size: 14px;
          height: 40px;
        }
        &::placeholder {
          color:#C2C2C2;
        }
      }
      .icon {
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 16px;
        margin: auto;
        @media #{$sp} {
          left: 16px;
        }
      }
    }
  }

  .faq-body {
    .description {
      padding-bottom: 36px;
      text-align: center;
      font-size: 16px;

      @media #{$sp} {
        font-size: 14px;
      }
    }

    .faq-other-tilte {
      font-size: 24px;
      max-width: 912px;
      margin: 120px auto 24px;
      line-height: 1;
      padding: 56px 0 0;
      border-top: 1px solid #D7DCDF;
      @media #{$sp} {
        font-size: 20px;
        padding: 32px 0 0;
        margin: 80px 12px 32px;
        max-width: none;
        line-height: 1.5;
      }
    }

    .faq-detail {
      font-size: 16px;
      line-height: 1.8;
      max-width: 960px;
      margin: 0 auto;
      padding: 0 24px;
      color: #333333;
      @media #{$sp} {
        font-size: 14px;
        max-width: none;
        padding: 0 18px;
      }
      img {
        width: 100%;
      }
    }

    .section-inner {
      max-width: 960px;
      margin: 0 auto;
      padding: 0 24px;
      @media #{$sp} {
        max-width: none;
        padding: 0 18px;
      }
    }

    .faq-tables {
      display: flex;
      flex-wrap: wrap;

      table {
        margin:0 5% 36px 5%;
        width: 40%;

        th {
          color: #414040;
          border: none;
          background-color: #F2F2F2;
          font-size: 15px;
          font-weight: bold;
        }
        tbody {
          td {
            border: initial;
            border-bottom: 1px solid #DCDCDC;
            font-size: 13px;
            padding: 16px 16px 12px 2px;
            a {
              color: #7F7F7F;
            }
          }
        }
        tfoot {
          td { 
            border: none;
            font-size: 12px;
            padding-left: 12px;
          }
        }
      }
    }
    .faq-item {
      font-size: 16px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      line-height: 1.3;
      &:first-child {
        border-top: 1px solid #D7DCDF;
      }
      &:last-child {
        border-bottom: 1px solid #D7DCDF;
      }
      & + .faq-item {
        border-top: 1px solid #D7DCDF;
      }
      a {
        font-size: 16px;
        color: #333;
        @media #{$sp} {
          font-size: 14px;
        }
      }
    }
  }

  .faq-footer {
    background: #F8F8F8;
    height: 280px;
    display: flex;
    justify-content: center;
    .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media #{$sp} {
        padding: 0 18px;
      }
      >p {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
      .btn {
        margin-bottom: 40px;
        background: #fff;
        color: #333;
        border: 1px solid #333;
      }
      .notice {
        margin: 0;
        list-style-type: none;
        font-size: 14px;
        color: #333;
        @media #{$sp} {
          font-size: 12px;
        }
        li {
          & + li {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .faqTopLink {
    margin: 80px 0 72px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    @media #{$sp} {
      margin: 48px 0 32px;
      font-size: 14px;
    }
  }

  .section-title {
    font-size: 32px;

    @media #{$sp} {
      font-size: 22px;
      margin-bottom: -10px;
    }
  }

  .section-faq {
    .section-inner {
      width: auto;
      max-width: 1200px;
    }

    .list-faq {
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        max-width: 1092px;
        width: 100%;
        border: 1px solid #d1d1d1;
      }
    }
  }

  .underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .tab-faq {
    max-width: 1092px;
    margin: 0 auto 26px;

    @media #{$sp} {
      margin-bottom: 8px;
    }

    .MuiTabs-flexContainer {
      justify-content: space-between;
      margin: 0;

      @media #{$sp} {
        flex-direction: column;
      }
    }

    .MuiTab-root {
      width: calc(95% / 3);
      height: 64px;
      background: #fff;
      font-size: 20px;
      font-weight: bold;
      padding: 0;
      line-height: 1.5;
      border-radius: 10px;
      border: 1px solid #d1d1d1;
      text-transform: none;

      @media #{$sp} {
        font-size: 16px;
        width: 100%;
        max-width: 100%;
        height: 56px;
        margin-bottom: 8px;
      }
    }

    .Mui-selected {
      background: #f0f0f0;
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  }

  .acc-contetns {
    .answer-ren-text {
      li {
        border: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .faq-list-header {
    display: block;
    padding-top: 32px;
    @media #{$sp} {
      padding-top: 16px;
    }
    .faqList-head-text {
      text-align: center;
      margin-bottom: 64px;
      @media #{$sp} {
        margin-bottom: 40px;
        padding: 0 18px;
      }
      h1 {
        font-size: 30px;
        line-height: 1.5;
        max-width: 960px;
        margin: 0 auto 40px;
        padding: 0 24px;
        @media #{$sp} {
          font-size: 20px;
          line-height: 1.5;
          margin-bottom: 12px;
        }
      }
      p {
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        @media #{$sp} {
          font-size: 18px;
        }
      }
    }
    .breadcrumbs {
      display: inline-block;
      width: auto;
      margin-left: 0;
      font-size: 13px;
      padding: 0;
      @media #{$sp} {
        font-size: 12px;
      }
      .inner {
        ul {
          gap: 24px;
          li {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              bottom: -2px;
              margin: auto;
              right: -16px;
              width: 6px;
              height: 6px;
              border-top: solid 2px #C2C2C2;
              border-right: solid 2px #C2C2C2;
              transform: rotate(45deg);
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
            span {
              color: #333333;
              max-width: none;
            }
          }
        }
      }
    }
    .faq-list-find {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      max-width: 960px;
      margin: 0 auto 56px;
      padding: 0 24px;
      @media #{$sp} {
        flex-direction: column;
        margin: 0 auto 32px;
        gap: 24px;
        padding: 0 18px;
      }
      .input-control {
        min-height: 48px;
        line-height: 48px;
        width: auto;
        position: relative;
        @media #{$sp} {
          min-height: 40px;
          line-height: 40px;
          width: 100%;
        }
        input {
          height: 48px;
          line-height: 48px;
          border-radius: 50px;
          width: 320px;
          border: 1px solid #CACACA;
          padding: 0px 24px 0 43px;
          @media #{$sp} {
            height: 40px;
            line-height: 40px;
            width: 100%;
          }
          &::placeholder {
            color: #C2C2C2;
          }
        }
        .icon {
          position: absolute;
          top: 2px;
          bottom: 0;
          left: 16px;
          margin: auto;
          @media #{$sp} {
            left: 16px;
          }
        }
      }
    }
  }
}

.faqContainer {
  width: 960px;
  margin: 0 auto;
  padding: 24px 24px 48px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 64px;
  @media #{$sp} {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0 32px;
    gap: 48px;
  }
  .faqBlock {
    .faqBlock__title {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 1;
      @media #{$sp} {
        font-size: 20px;
        margin-bottom: 24px;
      }
    }
  }
  .faqList {
    display: flex;
    flex-direction: column;
    .faqList__item {
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 1.3;
      @media #{$sp} {
        font-size: 14px;
      }
      &:first-child {
        border-top: 1px solid #D7DCDF;
      }
      &:last-child {
        border-bottom: 1px solid #D7DCDF;
      }
      & + .faqList__item {
        border-top: 1px solid #D7DCDF;
      }
      a {
        color: #333333;
        margin: 12px 0px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .faqList_showMore {
    font-size: 16px;
    padding-top: 12px;
    line-height: 1;
    text-align: right;
    @media #{$sp} {
      padding-top: 8px;
      font-size: 14px;
    }
    a {
      cursor: pointer;
      color: #008CD9;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
