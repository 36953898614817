// 投稿一覧・詳細、コメントの画像
.mediaImagesWrapper {
	&.-postList {
		margin: 32px auto;
		&.images-more {
			margin: 32px auto;
		}
		.media {
			margin-bottom: 0;
		}
	}
	.media {
		margin-top: 0;
		&:hover {
			cursor: pointer;
		}

		img {
			width: 100%;
			height: 100%;
			vertical-align: bottom;
			object-fit: cover;
		}

		// 5枚目以降は非表示
		&:nth-of-type(n+5) {
			display: none;
		}
	}

	// 複数枚の場合すべて
	&:not(.images-1) {
		margin: 32px auto;
		height: 310px;
		width: 548px;
		border: solid 1px #D7DDE2;
		border-radius: 8px;
		overflow: hidden;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 50% 50%;
		grid-column-gap: 2px;

		@include bkp(sp) {
			height: auto;
			width: 100%;
			aspect-ratio: 87/49;
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	// 1枚の場合
	&.images-1 {
		@include bkp(sp) {
			margin: 16px auto 32px;
		}
		.media {
			height: 310px;
			width: 548px;
			border: solid 1px #D7DDE2;
			border-radius: 8px;
			overflow: hidden;
			margin: 32px auto;
			@include bkp(sp) {
				height: auto;
				width: 100%;
				aspect-ratio: 87/49;
				margin: 16px auto 32px;
			}
		}
	}

	// 2枚の場合
	&.images-2 {
		.media {
			grid-row: 1/3;
			@include bkp(sp) {
				aspect-ratio: 29/33;
			}
		}
	}

	// 3枚の場合
	&.images-3 {
		grid-row-gap: 2px;

		.media {
			&:nth-of-type(1) {
				grid-row: 1/3;

				@include bkp(sp) {
					aspect-ratio: 29/33;
				}
			}
		}
	}

	// 4枚・5枚以上の場合
	&.images-4,
	&.images-more {
		grid-row-gap: 2px;
	}

	// 5枚以上の場合
	&.images-more {
		.media[data-more^="+"] {
			position: relative;

			&::before {
				content: attr(data-more);
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(#333333, 0.5);
				color: #fff;
				font-size: 24px;
				font-weight: 500;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
	}

	// コメント
	&.-commentImages {
		margin-top: 24px;
		margin-bottom: 24px;
		@include bkp(sp) {
			margin-top: 16px;
			margin-bottom: 16px;
		}
	}
}


.DraftEditor-root {
	padding-bottom: 20px;
	cursor: text;

	.public-DraftStyleDefault-block,
	.public-DraftEditorPlaceholder-inner {
		line-height: 20px;
	}

	.public-DraftEditorPlaceholder-root {
		position: absolute;
		left: 0;
		top: 0;
		color: #999;
		z-index: 1;
	}

	.DraftEditor-editorContainer {
		position: relative;
		left: 0;
		top: 0;
		z-index: 5;
	}

	.hngfxw3 {
		color: #45B5F5;
	}
}

.post_editor {
	.DraftEditor-root {
		overflow: scroll;
		min-height: 100px;
		padding: 0;
		font-size: 16px;
		position: relative;
		.public-DraftEditorPlaceholder-root {
			top: 8px;
			right: 0;
			bottom: 8px;
			left: 0;
		}
	}
	.DraftEditor-root::-webkit-scrollbar {
		display:none;
	}
}

.post_editor_sp {
	.textarea_wrap {
		::placeholder {
			color: #999;
		}
		textarea {
			width: 100%;
			height: 100%;
			border: none;
			resize: none;
			box-sizing: border-box;
			font-size: 16px;
			&:focus {
				outline: none;
			}
		}
	}
}

.comment_editor {
	& + .inputFooter {
		border-top: 0;
	}
	.DraftEditor-root {
		overflow: scroll;
		border: 1px solid #cbcbcb;
		border-radius: 3px;
		min-height: 80px;
		padding: 8px 6px;

		.public-DraftEditorPlaceholder-root {
			top: 8px;
			right: 6px;
			bottom: 8px;
			left: 6px;
		}
	}
	.DraftEditor-root::-webkit-scrollbar {
		display:none;
	}
}

.comment_editor_sp {
	& + .inputFooter {
		border-top: 0;
	}
	.textarea_wrap {
		::placeholder {
			color: #999;
		}
		textarea {
			box-sizing: border-box;
			padding: 8px 5px;
			width: 100%;
			height: 100%;
			border: 1px solid #b6c3c6;
			border-radius: 4px;
			resize: none;
			font-size: 16px;
			&:focus {
				outline: none;
			}
		}
	}
}

a {
	.commentHeading {
		.owner {
			.owner__item {

				.name,
				.accountLabel span,
				.action span svg {
					color: #333;
				}
			}
		}
	}
}

a {
	.commentBody {
		p {
			color: #333;

			span {
				color: #333;
			}
		}
	}
}

.hash_tag {
	color: #45B5F5;
}

.postList {
	.postList__item {}
}

.media {
	margin-top: 20px;
}


.content_omitted {
	text-align: center;
	font-weight: bold;
	margin: 16px 0 4px;
}

.content_omitted {
	color: #666;
	text-align: center;
	margin-top: 16px;
}

.post {
	padding-top: 16px;

	@include bkp(sp) {
		padding-top: 24px;
	}

	.postPin {
		color: transparent;
		text-shadow: 0 0 0 rgb(48, 48, 48);
		margin-bottom: 12px;
	}

	.postHeading {
		margin-bottom: 24px;

		.owner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.owner__item {
				line-height: 1;

				&.-left {
					display: flex;
					align-items: center;

					a {
						color: #333;
					}
				}

				&.-right {}
			}

			.icon {
				width: 48px;
				min-width: 48px;
				height: 48px;
				border-radius: 100%;
				margin-right: 8px;
			}

			.name {
				font-size: 15px;
				font-weight: 500;
				margin-bottom: 8px;

				a {
					color: #333;
				}

				line-height: 1.3;

				.accountLabel {
					font-size: 12px;
					padding-left: 10px;

					img {
						padding-right: 3px;
					}

					span {
						position: relative;
						bottom: 1.5px;
					}
				}
			}

			.date {
				font-size: 12px;
				font-weight: 400;
				color: #666;
			}
		}
	}

	.postBody {
		margin: 0 auto;
		padding-bottom: 24px;
		border-bottom: 1px solid #ddd;

		@media screen and (max-width: 846px) {
			padding: 0 24px 24px;
		}

		@include bkp(sp) {
			padding: 0 16px 24px;
		}

		.postBody__inner {
			color: #333;

			.mediaImagesWrapper {

				// 投稿一覧
				&.-postList {

					// 1枚の場合
					&.images-1 {
						img {}
					}
				}

				// 投稿詳細
				&.-postDetail {

					// 1枚の場合
					&.images-1 {
						img {}
					}
				}
			}
		}

		.postBodyFotter {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.postBodyFotter__left {
				@include bkp(sp) {
					margin-right: 24px;
				}
			}

			.postBodyFotter__right {
				display: flex;
				align-items: center;
				flex-shrink: 0;
			}

			.like {
				display: inline-block;
				cursor: pointer;
				margin-right: 6px;

				&.liked {
					svg {
						fill: #e0245e;
					}
				}

				svg {
					fill: #b2b2b2;
					vertical-align: -5px;
				}
			}

			.likeCount {
				color: #888;
				cursor: pointer;
				font-size: 14px;
				margin-right: 24px;

				@include bkp(sp) {
					margin-right: 16px;
				}

				svg {
					fill: #b2b2b2;
					margin-right: 8px;
					vertical-align: -5px;
				}
			}

			.commentCount {
				cursor: pointer;
				font-size: 14px;
				color: #888;

				img {
					margin-right: 8px;
					vertical-align: -3px;
				}
			}
		}
	}

	.postFooter {
		border: 1px solid #ddd;
		border-left: 0;
		border-right: 0;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;

		.postFooter__item {
			width: 50%;
			text-align: center;

			&+.postFooter__item {
				border-left: 1px solid #ddd;
			}
		}

		.like {
			display: inline-block;
			cursor: pointer;
			color: #333;
			font-weight: bold;

			&.liked {
				color: #e0245e
			}

			svg {
				// fill: #999;
				margin-right: 8px;
				vertical-align: -5px;
			}

			img {
				margin-right: 8px;
				vertical-align: -3px;
			}
		}

		.toComment {
			color: #333;
			font-weight: bold;
			cursor: pointer;

			svg {
				fill: #999;
				margin-right: 8px;
				vertical-align: -5px;
			}

			img {
				margin-right: 8px;
				vertical-align: -3px;
			}
		}
	}

	.postContent {
		font-size: 16px;
		margin-bottom: 40px;

		@include bkp(sp) {
			margin-bottom: 24px;
		}

		a {
			color: #333;
		}

		h2 {
			font-size: 22px;
			font-weight: bold;
			margin-bottom: 16px;
		}

		.image {
			display: flex;
			justify-content: center;
			margin: 40px 0;

			img {
				max-width: 610px;
				height: auto;
			}
		}

		a.hashtag {
			color: #45B5F5;
		}
	}

	.roomTag {
		font-size: 12px;
		border: 1px solid #333;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0 16px;
		height: 24px;
		border-radius: 24px;
		box-sizing: border-box;

		@include bkp(sp) {
			padding: 0 16px;
			font-size: 11px;
		}
	}
}

.postInput {
	padding: 32px 24px;
	flex-basis: 688px;
	margin: 0 auto;

	.postInput__item {
		&+.postInput__item {
			margin-top: 40px;
			@media (max-width: 768px) {
				margin-top: 10px;
			}
		}
	}

	.entryBtnWrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 40px;
		@media (max-width: 768px) {
			margin-bottom: 10px;
		}
		.cancel {
			color: #999;
			margin-right: 32px;
			cursor: pointer;
		}

		.entry {
			font-size: 14px;
			font-weight: bold;
			background: #26bfdb;
			color: #fff;
			height: 32px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 40px;
			border: 0;
			cursor: pointer;

			&:disabled {
				background: lightgray;
			}

			&:hover {
				opacity: .9;
				color: #fff;
			}
		}
	}
}

// ファイル選択アイコン
.inputFooter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	border-top: 1px solid #ddd;
	.counter {
		position: static;
	}

	.select-file {
		cursor: pointer;

		&.-default {
			padding: 8px;

			img {
				width: 24px;
				height: 24px;
			}
		}

		&.-small {
			width: 20px;
			height: 20px;

			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}

// LightBoxのモーダル
// ライブラリのデフォルトスタイルを上書き
.ril-outer {

	// Prev・Nextボタン
	.ril__navButtons {
		width: 60px;
		background-color: transparent;
	}

	// Prev・Nextボタンを非表示にするクラス
	.prevNextButtonHidden {

		.ril-prev-button,
		.ril-next-button {
			display: none;
		}
	}
}
