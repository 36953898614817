.subscriptionList {
	max-width: 1040px;
	margin: 0 auto;
	a {
		color: inherit;
	}
	.subscriptionList__item {
		padding: 16px;
		& + .subscriptionList__item {
			border-top:1px solid #d7dcdf;
		}
	}
	.subscription {
		display: flex;
		justify-content: space-between;
		padding: 16px;
		margin-top: 16px;
		border:1px solid #d7dcdf;
		& + .subscription {
			margin-top: 8px;
		}
		.subscription__item {
			&.-left {
				margin-right: 16px;
			}
			&.-right {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-between;
			}
		}
	}
	.userInfo {
		display: flex;
		.userInfo__item {
			&.-left {
				flex-grow: 0;
				margin-right: 16px;
			}
			&.-right {
				flex-grow: 1;
			}
		}
		.icon {
			width: 40px;
			height: 40px;
			border-radius: 100%;
		}
		.doorTitle {
			font-weight: bold;
			font-size: 14px;
			line-height: 1.3;
		}
		.userId {
			font-size: 12px;
			color: #a2a2a2;
		}
		.planName {
			font-weight: bold;
			font-size: 14px;
			line-height: 1.3;
			margin-bottom: 8px;
		}
		.period {
			font-size: 12px;
			color: #a2a2a2;
		}
		.price {
			line-height: 1;
			margin-bottom: 8px;
			font-weight: bold;
			white-space: nowrap;
			font-size: 13px;
			span {
				font-size: 16px;
			}
		}
	}
}

.memberListContent {

}
.memberListToolbar {
	display: flex;
	align-items: center;
	justify-content: end;
	margin-bottom: 32px;
	.memberListToolbar__display {
		font-size: 14px;
		@media #{$sp} {
			display: flex;
			flex-direction: column;
		}
		.num {
			font-size: 14px;
			font-weight: bold;
		}
	}
	.memberListToolbar__filter {
		position: relative;
		display: flex;
		gap: 16px;
		@media #{$sp} {
			gap: 8px;
		}
	}
	.memberListToolbar__button {
    display: flex;
    align-items: center;
    justify-content: center;
		width: 105px;
		height: 30px;
		font-size: 12px;
		background: #fff;
		border: 0;
		border-radius: 4px;
		border: 1px solid #D7DCDF;
		cursor: pointer;
		padding: 0;
		&.-invitation {
			background: #45B5F5;
			color: #fff;
			border: 1px solid #45B5F5;
			font-weight: bold;
			@media #{$sp} {
				width: 82px;
			}
			.icon {
				font-size: 11px;
				margin-right: 4px;
			}
		}
		&.-filter {
			color: #333;
			@media #{$sp} {
				width: 112px;
			}
			.icon {
				margin-right: 4px;
			}
			&.-filterChecked {
				.filterCount {
					color: #45B5F5;
				}
			}
			.filterCount {
				border-left: 1px solid #D7DCDF;
				padding-left: 8px;
				margin-left: 8px;
			}
		}
	}
	.filterBox {
		position: absolute;
		top: 30px;
		right: 0;
		background: #fff;
		padding: 24px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 4px;
		z-index: 10;
		.filterList {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-bottom: 16px;
			.filterList__item {
			}
		}
		.filter {
			margin: 0;
			display: flex;
			align-items: center;
			gap: 8px;
			vertical-align: unset;
			.MuiButtonBase-root {
				padding: 0;
			}
			.MuiTypography-root {
				font-size: 12px;
				font-weight: bold;
			}
		}
		.filterApplierButton {
			background: #45B5F5;
			color: #fff;
			border: 0;
			border-radius: 4px;
			width: 100%;
			height: 26px;
			font-size: 12px;
			font-weight: bold;
			cursor: pointer;
		}
	}
}
.memberList {
	margin-bottom: 32px;
	@media screen and (max-width: 640px) {
		margin: 0 -12px 32px;
		background: #f8f8f8;
		max-width: 100vw;
	}
}
.membersTableContainer {
	box-shadow: none !important;
	@media screen and (max-width: 640px) {
		padding: 12px;
		&.MuiTableContainer-root {
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
		}
	}
}
.membersTable {
	width: 100%;
	overflow: hidden;
	.MuiTableCell-root {
		font-family: "Noto Sans JP", sans-serif;
	}
	th, tr, td {
		border: 0;
		white-space: nowrap;
	}
	th ,td {
		font-weight: 500;
		padding: 16px 12px;
		&:nth-child(1) {
			width: 40%;
			max-width: 230px;
		}
		&:nth-child(2) {
			width: 15%;
		}
		&:nth-child(3) {
			width: 40%;
			max-width: 230px;
		}
		&:nth-child(5) {
			width: 5%;
			text-align: center;
		}
	}
	thead {
		border-bottom: 2px solid #D7DCDF;
		th {
			font-size: 14px;
			font-weight: 700;
			color: #111;
		}
	}
	tbody {
		tr {
			border-bottom: 1px solid #D7DCDF;
			padding-bottom: 16px;
			margin-bottom: 16px;
		}
		td {
			font-size: 12px;
			.MuiFormControlLabel-root {
				margin-left: 0;
				margin-right: 0
			}
		}
	}
	.userInfo {
		font-size: 12px;
		display: flex;
		align-items: center;
		gap: 24px;
		color: #333;
		@media #{$sp} {
			gap: 12px;
		}
		.icon {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			flex-shrink: 0;
			cursor: pointer;
		}
		.userName {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 13px;
			cursor: pointer;
			@media #{$sp} {
				font-size: 14px;
			}
		}
	}
	.planNameList {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.planNameList__item {
			display: inline-flex;
			align-items: center;
			& + .planNameList__item {
				padding-top: 12px;
			}
		}
		.planName {
			font-size: 13px;
			height: 21px;
			font-weight: 500;
			flex-shrink: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			line-height: 21px;
			max-width: 100%;
		}
	}
	.userPositionWrap {
		display: block;
		& + .userPositionWrap{
			padding-top: 20px;
		}
	}
	.userPosition {
		background: #F2F2F2;
		border-radius: 3px;
		font-size: 11px;
		color: #888888;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 11px;
		font-weight: 500;
		flex-shrink: 0;
		@media #{$sp} {
			width: 88px;
		}
		&.-adminOwner,
		&.-generalOwner,
		&.-staff {
			background: #FFD791;
			color: #DB8D21;
		}
		&.-invitationMember {
			background: #C6E9FF;
			color: #1A94DE;
		}
	}
	.unsubscribe {
		color: #666;
		cursor: pointer;
		& + .unsubscribe {
			margin-top: 20px;
		}
		@media #{$sp} {
			height: 21px;
			line-height: 21px;
		}
	}
}
