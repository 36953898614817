
#modal-mask {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    &.hidden {
        display: none;
    }
}

.modal-header.no_bdr {
    .title {
        border: none;
        p {
            font-size: 14px;
        }
    }
    .close.invert {
        svg {
            color: #fff;
            background-color: #888;
            border-radius: 10px;
            padding: 3px;
            height: 20px;
        }
    }
}
.modal-contents.btn_red {
    .modal-confirmationButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
            background-color: #fff;
            border: 1px solid #ff6a70;
            color: #ff6a70;
            height: 35px;
            width: 130px;
            margin-left: 10%;
            &:disabled {
                background: #eee;
                color: rgba(0, 0, 0, 0.26);
                border: none;
            }
        }
        .textLink {
            margin-right: 10%;
        }
    }
}

.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    background:#fff;
    box-shadow: 0 12px 12px 1px rgba(0, 0, 0, .16);
    width: 560px;
    max-height: 90vh;
    transition: .3s ease-in-out;
    background: #fff;
    overflow: auto;
    border-radius: 6px;
    opacity: 1;
    @media #{$sp} {
        width: 90vw;
    }
    &.-favorite {
        min-height: 500px;
        max-height: 70vh;
        width: 480px;
        overflow: hidden;
        @media #{$sp} {
            width: 90vw;
        }
        .modal-header {
            position: sticky;
            top: 0;
            background: #fff;
        }
        .modal-contents {
            padding: 0;
            overflow: auto;
            max-height: 61vh;
        }
    }
    &.-push {
        max-width: 514px;
    }
    // &.hidden {
    //     display: none;
    //     opacity: 0;
    // }
    &.-cancelFreeTriale {
        width: 640px;
        @media #{$sp} {
            width: 90vw;
            max-height: 70vh;
            overflow-y: scroll;
        }
        .modal-header {
            .title {
                font-size: 20px;
                @media #{$sp} {
                    font-size: 16px;
                }
                border-bottom: 0;
                span {
                    color: #f34747;
                }
            }
        }
        .modal-contents {
            padding: 32px;
            padding-top: 0;
            @media #{$sp} {
                padding: 32px 16px;
                padding-top: 0;
            }
        }
    }
    &.-deleteSalon {
        width: 650px;
        @media #{$sp} {
            width: 90%;
        }
    }
    .position-tag-0,
    .position-tag-1,
    .position-tag-2,
    .position-tag-3 {
		display: inline-flex;
        align-items: center;
        height: 21px;
        border-radius: 3px;
        padding: 0 11px;
        font-weight: 500;
        line-height: 1;
    }
    .position-tag-0 {
        background: #C6E9FF;
        color: #1A94DE;
    }
    .position-tag-1,
    .position-tag-2,
    .position-tag-3 {
        background: #FFD791;
        color: #DB8D21;
    }
    &.-bookmark {
        @media #{$pc} {
            width: 460px;
        }
        .modal-header {
            .title {
                border-bottom: 0;
                @media #{$sp} {
                    font-size: 16px;
                }
            }
        }
        .modal-contents {
            padding: 0 20px 20px;
            @media #{$sp} {
                padding: 0 20px 20px;
            }
        }
    }
}
.modal-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px 40px;
    .modal-itemText {
        font-size: 14px;
    }
}

.modal-drawer {
    position: relative;
    box-sizing: border-box;
    top:0;
    right:0;
    z-index: 99999;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .16);
    width: 320px;
    height: 100%;
    transition: .3s ease-in-out;
    overflow: auto;
    padding-bottom: $title-height;
    @media #{$sp} {
        width: 320px;
        padding-bottom: 0;
    }
    &.hidden {
        right:-320px;
    }
    &.-room {
        background: #fff;
    }
}

.modal-action {
    position: fixed;
    box-sizing: border-box;
    bottom:0;
    right:0;
    z-index: 99999;
    background:#fff;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .16);
    width: 100%;
    height: auto;
    transition: .3s ease-in-out;
    &.hidden {
        bottom:-320px;
    }
}

.modal-header{
    position: relative;
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 16px 16px;
        text-align: center;
        font-size: 16px;
        @include bold;
        border-bottom: 1px solid $border-color;
        position: relative;
        @media #{$sp} {
            font-size: 15px;
        }
        &.-noDelimiter {
            
            font-size: 24px;
            border: none;
            @media #{$sp} {
                font-size: 16px;
            }
        }
        .description {
            font-size: 13px;
            font-weight: normal;
            margin-top: 8px;
        }
        .required {
            position: absolute;
            margin-top: 3px;
            margin-left: 10px;
            font-size: 10px;
            font-size: 10px;
            color: white;
            background: #ff6d6d;
            padding: 1px 7px 2px;
            border-radius: 3px;
        }
    }
    .close{
        cursor: pointer;
        position: absolute;
        top:12px;
        right: 12px;
        color:$text-notice-color;
        .material-icons,
        svg {
            width: 28px;
            font-size:28px;
            color:$text-notice-color;
        }
    }
    &.-keywordSelect {
        .title{
            border-bottom: 0 solid $border-color;
        }
    }
}

.modal-contents{
    padding: 20px;
    &.-push {
        padding: 0;
    }
    &.-deleteSalon {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 8px 48px 32px;
        @media #{$sp} {
            gap: 30px;
            padding: 8px 16px 32px;
        }

        .caution {
            margin-bottom: 10px;
            font-weight: bold;
        }

        .confirm {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 48px;
            @media #{$sp} {
                gap: 16px;
            }
        }

        .form-submit {
            padding: 0;
            display: flex;
            justify-content: space-evenly;
            width: 100%;
        }
    }
    .modal-contents__inner {
        display: flex;
        padding: 32px;
        @media #{$sp} {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding: 32px 16px;
        }
        p {
            font-weight: bold;
            margin-top: 16px;
            @media #{$sp} {
                font-size: 14px;
            }
        }
    }
    .modal-contents__footer {
        border-top: 1px solid $border-color;
        padding: 16px;
    }
    .btn-later {
        margin-right: 32px;
        font-weight: bold;
        color: #11B5F4;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            color: $link-text-color;
        }
    }
    .btn-push {
        background: #11B5F4;
        padding: 6px 12px;
        border-radius: 6px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        &:hover {
            background: $link-text-color;
        }
    }
}

.pushSetting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$sp} {
        flex-direction: column;
    }
    .pushSetting__left {
        font-size: 13px;
        display: flex;
        align-items: center;
        @media #{$sp} {
            margin-bottom: 13px;
        }
    }
    .pushSetting__right {
        display: flex;
        align-items: center;
    }
}

.currentDoorName {
    padding: 16px 32px;
    font-weight: 700;
    @media #{$sp} {
        padding: 16px 24px;
    }
}

.modal-user{
    background: #fff;
    display: block;
    padding: 48px 0 32px;
    text-align: center;
    .icon{
        margin: 0 auto 10px auto;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        border:2px solid #fff;
        display: block;
    }
    .username{
        color:$text-strong-color;
        font-size: 13px;
    }
    .title {
        font-size: 15px;
        @include bold;
    }
    &.-userMenu {
        display: flex;
        align-items: center;
        padding: 16px 32px;
        border-bottom: 1px solid #D7DCDF;
        margin-bottom: 16px;
        gap: 16px;
        @media #{$sp} {
            gap: 12px;
            padding: 16px 24px;
        }
        .icon {
            width: 48px;
            height: 48px;
            margin: 0;
            border: 0;
            flex-shrink: 0;
        }
        .username {
            text-align: left;
        }
    }
}

.modal-list{
    margin: 0 0 20px 0;
    li{
        div,
        a {
            background: #fff;
            color: $text-base-color;
            display: block;
            padding: 16px 48px;
            font-size: 14px;
            cursor: pointer;
            @media #{$sp} {
                padding: 16px 40px;
            }
        }
        &.active {
            div,
            a {
                color: $link-color;
            }
        }
        &.borderTop {
            border-top: 1px solid #d7dcdf;
            margin-top: 16px;
            padding-top: 16px;
        }
        &.borderBottom {
            border-bottom: 1px solid #d7dcdf;
            margin-bottom: 16px;
            padding-bottom: 16px;
        }
    }
}

.modal-price{
    margin-bottom: 20px;
    text-align: center;
    .description{
        margin-top: 10px;
        @include bold;
        color: $text-base-color;
        strong{
            font-size: 16px;
            color:$main-color;
        }
    }
}

.modal-leave {
    .modal-contents {
        .modal-user {
            &:nth-of-type(1) {
                padding: 0 20px;
            }
            &:nth-of-type(2) {
                padding: 0 20px 20px;
            }
            &>.icon {
                &>.MuiAvatar-root {
                    width: 80px;
                    height: 80px;
                }
            }
        }
        &>hr {
            margin: 20px 0;
        }
        &>.MuiDialogContentText-root {
            margin-bottom: 8px;
        }
    }
}

.modalSalonInfo {
    display: flex;
    padding: 16px 0;
    border-top: 1px solid #d7dcdf;
    border-bottom: 1px solid #d7dcdf;
    margin-bottom: 8px;
    @media #{$pc} {
        gap: 24px;
        align-items: center;
    }
    @media #{$sp} {
        gap: 16px;
        flex-direction: column;
    }
    .modalSalonInfo__item {
        &.-left {
            flex-basis: 75%;
            @media #{$sp} {
                flex-basis: 100%;
            }
        }
        &.-right {
            flex-basis: 25%;
            @media #{$sp} {
                display: none;
                flex-basis: 100%;
                text-align: center;
            }
        }
        img {
            aspect-ratio: 16/9;
            object-fit: cover;
            max-width: 240px;
            vertical-align: bottom;
            @media #{$sp} {
                max-width: none;
                width: 75%;
            }
        }
    }
    .modalSalonName {
        margin-bottom: 16px;
        @media #{$sp} {
            margin-bottom: 12px;
        }
        p {
            font-weight: bold;
            line-height: 1;
            margin-bottom: 8px;
            @media #{$sp} {
                margin-bottom: 4px;
                font-size: 13px;
            }
        }
        h3 {
            font-size: 15px;
            font-weight: 400;
            @media #{$sp} {
                font-size: 14px;
            }
        }
    }
    .modalPlanName {
        p {
            font-weight: bold;
            line-height: 1;
            margin-bottom: 8px;
            @media #{$sp} {
                margin-bottom: 4px;
                font-size: 13px;
            }
        }
        h3 {
            font-size: 15px;
            font-weight: 400;
            @media #{$sp} {
                font-size: 14px;
            }
        }
    }
    .modalSalonImage {

    }
}
.modalSalonInfoNotice {
    p {
        font-size: 12px;
        color: #f34747;
        & + p {
            margin-top: 4px;
        }
    }
}
.modalSalonButtonArea {
    margin-top: 32px;
    @media #{$sp} {
        margin-top: 32px;
    }
    .sp-only {
        display: none;
        @media #{$sp} {
            display: block;
        }
    }
    .modalSalonButtonWrap {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 24px;
        gap: 24px;
        @media #{$sp} {
            gap: 16px;
            flex-direction: column;
            align-items: center;
        }
    }
    .modalSalonButton {
        font-size: 14px;
        font-weight: bold;
        background: #45b5f5;
        color: #fff;
        width: 200px;
        height: 48px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        line-height: 48px;
        flex-shrink: 0;
        border: 0;
        cursor: pointer;
        @media #{$sp} {
            width: 240px;
            height: 40px;
        }
        &:hover {
            opacity: 0.8;
        }
        &.-cancel {
            background: #fff;
            color: #707070;
            border: 1px solid #707070;
        }
        &.-unSubscribe {
            background: #fff;
            color: #f34747;
            border: 1px solid #f34747;
        }
    }
}

.delete-modal {
    .deleteModal-item {
        text-align: center;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & + .deleteModal-item {
            margin-top: 25px;
        }
        &.delete {
            color: #f34747;
        }
    }
}

// 確認ダイアログ用：テキストリンクとボタンの両方があるもの
.modal-confirmationButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    > .textLink {
        margin: 0 10%;
    }
    > .btn {
        height: 35px;
        width: 130px;
        margin-left: 10%;
    }
}

// 決済失敗エラーを表示するモーダル
.modal-error {
    &__message {
        text-align: center;
        margin: 12px 0 32px;
        .attention {
            font-size: 12px;
            margin-top: 8px;
        }
    }
}
