.global-header {
    width: 100%;
    background: #fff;
    height: 54px;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #0000001a;
    padding: 0 24px 0 8px;

    &.global-header-center {
        justify-content: center;
    }

    .logo {
        height: $header-height;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            img {
                display: block;
            }
        }
    }

    ul {
        display: flex;

        &.pc-only {
            @media #{$sp} {
                display: none;
            }
        }

        &.sp-only {
            display: none;

            @media #{$sp} {
                display: block;
            }
        }

        li {
            &+li {
                margin-left: 12px;
            }

            a,
            .icon {
                display: block;
                position: relative;
                cursor: pointer;
                color: $text-base-color;

                .icons {
                    width: 40px;
                    height: 40px;
                    border-radius: 25px;
                    background-repeat: no-repeat;
                    background-position: center;
                    -webkit-transition: 0.3s ease;
                    transition: 0.3s ease;
                    text-indent:100%;
                    white-space:nowrap;
                    overflow:hidden;
                    &.-notice {
                        background-image: url(../components/landingpage/assets/icon_notice.svg);
                    }
                    &.-menu {
                        background-image: url(../components/landingpage/assets/icon_mypage.svg);
                    }
                }

                .count {
                    position: absolute;
                    border-radius: 3px;
                    font-size: 10px;
                    @include bold;
                    background: $highlight-red-color;
                    color: #fff;
                    top: -6px;
                    right: -8px;
                    box-sizing: content-box;
                    display: block;
                    text-align: center;
                    min-width: 18px;
                    height: 14px;
                    line-height: 14px;
                }
            }
        }
    }
}

.contents {
    width: 640px;
    box-sizing: border-box;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    border-top: none;
    // box-shadow: 0 2px 12px 6px rgba(0, 0, 0, .03);
    border: 2px solid #e2e2e2;
    &.-terms {
        width: 860px;
    }

    &.-commentDetails {
        width: 760px;
        background: transparent;
        box-shadow: none;

        @media #{$sp} {
            width: 100%;
        }
    }

    &.-register {
        width: fit-content;
        box-shadow: none;
        border-top: 0;

        @media #{$pc} {
            padding: 32px 40px;
            border: 2px solid #E2E2E2;
        }

        @media #{$sp} {
            width: 100%;
        }

        .contents-header {
            @media #{$pc} {
                padding-top: 0;
            }

            @media #{$sp} {
                padding-top: 0;
            }
        }
    }

    @media #{$sp} {
        width: 100%;
        border-radius: 0;
        // border-top: 1px solid $border-color;
        margin: 0;
        box-shadow: none;
        border: 0;
        &.-terms {
            width: 100%;
        }
    }

    .form-block {
        .form-wrap {
            position: relative;

            .counter {
                position: absolute;
                bottom: 5px;
                right: 8px;
                color: rgba(136, 136, 136, 0.86);
                font-size: 13px;
            }
        }
    }
}

.global-footer {
    background: $footer-color;
    padding: 40px 40px 80px 40px;

    @media #{$sp} {
        padding: 24px 16px 40px 16px;
    }

    >.inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .footerLinkList {
        display: flex;
        color: #fff;
        margin-bottom: 64px;

        @media #{$sp} {
            width: 100%;
            flex-direction: column;
        }

        .linkList {
            @media #{$sp} {
                width: 50%;
            }

            &+.linkList {
                margin-left: 80px;

                @media #{$sp} {
                    width: 50%;
                    margin-left: 0;
                }
            }

            .linkListInnerWrap {
                display: flex;

                .linkListInnerWrap__item {
                    @media #{$sp} {
                        width: 100%;
                        flex-shrink: 0;
                    }

                    &+.linkListInnerWrap__item {
                        @media #{$pc} {
                            margin-left: 64px;
                        }
                    }
                }
            }
        }

        ul {
            li {
                margin-top: 16px;

                @media #{$sp} {
                    margin-top: 8px;
                }
            }
        }

        p {
            font-size: 15px;
            font-weight: bold;
        }

        a {
            color: #fff;
            font-size: 13px;
        }

        .footerLinkList__left {
            display: flex;

            @media #{$sp} {
                width: 100%;
                margin-bottom: 32px;
            }
        }

        .footerLinkList__right {
            border-left: 1px solid #fff;
            margin-left: 64px;
            padding-left: 64px;

            @media #{$sp} {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }
        }
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .divider {
            width: 1px;
            height: 30px;
            background: #fff;
            margin: 0 30px;
        }
    }

    .logo {
        text-align: center;
        line-height: 1;
    }

    // ul{
    //     text-align: center;
    //     margin-bottom: 10px;
    //     padding: 0;
    //     li{
    //         margin: 0 4px;
    //         display: inline-block;
    //         @media #{$sp} {
    //             margin: 0 2px;
    //         }
    //         a{
    //             font-size: 10px;
    //             color: #fff;
    //             &:hover{
    //                 text-decoration: underline;
    //             }
    //         }
    //     }
    // }
    .copyright {
        text-align: center;
        font-size: 10px;
        color: #fff;
    }
}

.customizeFooter {
    background: #505e74;
    padding: 24px 24px 8px;

    .footerLinkList {
        margin-bottom: 32px;

        @media #{$pc} {
            display: grid;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px 32px;
            grid-template-columns: repeat(7, auto);
        }

        @media #{$sp} {
            display: grid;
            gap: 8px;
            grid-template-columns: auto auto;
        }

        // .linkList {
        //     & + .linkList {
        //         @media #{$pc} {
        //             margin-left: 32px;
        //         }
        //     }
        // }
        a {
            color: #fff;
            font-size: 12px;
        }
    }

    .copyright {
        text-align: center;
        font-size: 12px;
        color: #fff;
    }
}

.keywordSelect {
    .keywordSelect__item {}

    .keywordHeading {
        font-size: 13px;
        @include bold;
        border-bottom: 1px solid $border-color;
        padding-bottom: 8px;
    }

    .keywordBody {
        padding: 16px 8px;
    }

    .keyword {
        display: inline-block;
        border: 1px solid #333;
        color: #333;
        border-radius: 20px;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 5px 12px;
        font-size: 13px;
        letter-spacing: -.05em;

        &.active {
            background: #ff6d6d;
            color: #fff;
        }

        &.inactive {
            background: silver;
        }
    }
}
