.text-left{text-align: left !important;}
.text-right{text-align: right !important;}
.text-center{text-align: center !important;}
.text-underline {text-decoration: underline !important;}
.text-nowrap{white-space: nowrap !important;}
.mt0{margin-top: 0 !important;}
.mb0{margin-bottom: 0 !important;}
.mt5{margin-top: 5px !important;}
.mb5{margin-bottom: 5px !important;}
.mt10{margin-top: 10px !important;}
.mt15{margin-top: 15px !important;}
.mb10{margin-bottom: 10px !important;}
.mb15{margin-bottom: 15px !important;}
.mt20{margin-top: 20px !important;}
.mb20{margin-bottom: 20px !important;}
.mt30{margin-top: 30px !important;}
.mb30{margin-bottom: 30px !important;}
.mt40{margin-top: 40px !important;}
.mb40{margin-bottom: 40px !important;}
.pt0{padding-top: 0px !important;}
.pt5{padding-top: 5px !important;}
.pt10{padding-top: 10px !important;}
.pt15{padding-top: 15px !important;}
.pt20{padding-top: 20px !important;}
.pt25{padding-top: 25px !important;}
.pt30{padding-top: 30px !important;}
.pt50 {padding-top: 50px !important;}
.pb0{padding-bottom: 0px !important;}
.pb5{padding-bottom: 5px !important;}
.pb10{padding-bottom: 10px !important;}
.pb15{padding-bottom: 15px !important;}
.pb20{padding-bottom: 20px !important;}
.pb25{padding-bottom: 25px !important;}
.pb30{padding-bottom: 30px !important;}

.mt1-half {margin-top: 4px !important;}
.mt1 {margin-top: 8px !important;}
.mt2 {margin-top: 16px !important;}
.mt3 {margin-top: 24px !important;}
.mt4 {margin-top: 32px !important;}
.mt5 {margin-top: 40px !important;}
.mt6 {margin-top: 48px !important;}
.mt7 {margin-top: 56px !important;}
.mt8 {margin-top: 64px !important;}
.mt9 {margin-top: 72px !important;}
.mt10 {margin-top: 80px !important;}

.mb1-half {margin-bottom: 4px !important;}
.mb1 {margin-bottom: 8px !important;}
.mb2 {margin-bottom: 16px !important;}
.mb3 {margin-bottom: 24px !important;}
.mb4 {margin-bottom: 32px !important;}
.mb5 {margin-bottom: 40px !important;}
.mb6 {margin-bottom: 48px !important;}
.mb7 {margin-bottom: 56px !important;}
.mb8 {margin-bottom: 64px !important;}
.mb9 {margin-bottom: 72px !important;}
.mb10 {margin-bottom: 80px !important;}

.pt1 {padding-top: 8px !important;}
.pt2 {padding-top: 16px !important;}
.pt3 {padding-top: 24px !important;}
.pt4 {padding-top: 32px !important;}
.pt5 {padding-top: 40px !important;}
.pt6 {padding-top: 48px !important;}
.pt7 {padding-top: 56px !important;}
.pt8 {padding-top: 64px !important;}
.pt9 {padding-top: 72px !important;}
.pt10 {padding-top: 80px !important;}

.pb1 {padding-bottom: 8px !important;}
.pb2 {padding-bottom: 16px !important;}
.pb3 {padding-bottom: 24px !important;}
.pb4 {padding-bottom: 32px !important;}
.pb5 {padding-bottom: 40px !important;}
.pb6 {padding-bottom: 48px !important;}
.pb7 {padding-bottom: 56px !important;}
.pb8 {padding-bottom: 64px !important;}
.pb9 {padding-bottom: 72px !important;}
.pb10 {padding-bottom: 80px !important;}

.border-top{border-top: 1px solid $border-color !important;}
.border-bottom{border-bottom: 1px solid $border-color !important;}
.text-notice{color:$text-notice-color;}
.table-fixed{table-layout: fixed;}
.table-center{th,td{text-align: center;}}
.table-vtop{th,td{vertical-align: top;}}
.hide{display: none;}

.text-sm{
    font-size: 12px;
}
.text-md{
    font-size: 14px;
}
.text-xs{
    font-size: 10px;
}

a{
    color: $link-text-color;
    text-decoration: none;
}
.link-color {
    cursor: pointer;
    color: $link-text-color;
}
.pc-only{
    display: block;
    @media #{$sp} {
        display: none;
    }
}

.sp-only{
    display: none;
    @media #{$sp} {
        display: block;
    }
}

strong{
    @include bold;
}

.text-bold{
    @include bold;
}

.marker{
    @include bold;
    background: linear-gradient(transparent 60%, #c1e874 60%);
}

.highlight{
    color: $highlight-color;
}

p.basic{
    font-size: 14px;
    line-height: 1.8;
    color: $text-base-color;
    margin: 0 0 10px 0;
}

dl.basic{
    dt{
        font-size: 16px;
        @include bold;
        margin: 0 0 4px 0;
        background: $light-color;
        padding: 4px;
        border-radius: 2px;
        span{
            font-size: 12px;
            font-weight: normal;
            margin: 0 0 0 8px;
            color: $text-notice-color;
        }
    }
    dd{
        font-size: 14px;
        line-height: 1.6;
        color: $text-base-color;
        padding: 4px;
        margin: 0 0 10px 0;
    }
}

ul.basic{
    font-size: 14px;
    color: $text-base-color;
    margin: 0 0 20px 20px;
    list-style-type: disc;
    li{
        margin: 0;
        padding: 2px 0;
    }
}

ul.notice{
    font-size: 10px;
    color: $text-notice-color;
    margin: 0 0 10px 20px;
    list-style-type: disc;
    li{
        margin: 0 0 3px 0;
        line-height: 1.2;
    }
}

.notice{
    font-size: 12px;
    line-height: 1.4;
    color: $text-notice-color;
    font-weight: normal;
    @media #{$sp} {
        font-size: 11px;
    }
}

.noticeText {
    margin-top: 8px;
    margin-left: 5px;
    font-size: 10px;
    letter-spacing: -0.05em;
    & + .noticeText {
        margin-top: 3px;
    }
    .point {
        margin-right: 3px;
        background: #ffe06d;
        color: #4e3d00;
        padding: 1px 4px;
        border-radius: 20px;
        letter-spacing: 0;
    }
    .caution {
        margin-right: 3px;
        background: #ff6d6d;
        color: #fff;
        padding: 1px 4px;
        border-radius: 20px;
        letter-spacing: 0;
        font-weight: bold;
    }
}

hr{
    height: 0;
    border:none;
    border-bottom: 1px solid $border-color;
    margin: 30px 0;
}

.alert{
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 14px;
}

.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.text-warning{
    color: #edb300;
}

.emptyState {
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #888888;
    .emptyState__title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 3px;
    }
}
.ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    &.-twoLines {
        -webkit-line-clamp: 2;
    }
    &.-threeLines {
        -webkit-line-clamp: 3;
    }
}
.togglePC {
    @media screen and (min-width: 847px) {
        display: block !important;
    }
    @media screen and (max-width: 846px) {
        display: none !important;
    }
}
.toggleSP {
    @media screen and (min-width: 847px) {
        display: none !important;
    }
    @media screen and (max-width: 846px) {
        display: block !important;
    }
}

.globalAlert {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    line-height: 1.3;
    min-height: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    @media screen and (max-width: 640px) {
        padding: 8px 12px;
        font-size: 12px;
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
}
.attention {
    background: #484848;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    @media screen and (max-width: 640px) {
        font-size: 12px;
    }
}
.caution {
    background: #F7F8A3;
    color: #333;
    a {
        color: #333;
    }
}
.warning {
    background: #FF6157;
    color: #fff;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type="number"] { 
    -moz-appearance:textfield; 
}

