.tabs {
	padding-bottom: 1px;
	border-bottom: 1px solid #F2F2F2;
	.MuiTabs-flexContainer {
		@include bkp(pc){
		}
		@include bkp(sp){
		}
	}
	.MuiTabs-indicator {
		background-color: #11B5F4;
		height: 3px;
	}
}
