.cssload-container {
    position: relative;
    display: block;
    margin: 49px auto;
    width: 97px;
    z-index: 1000;
}

.cssload-loading i {
    width: 19px;
    height: 19px;
    display: inline-block;
    border-radius: 50%;
    background: #11B5F5;
}

.cssload-loading i:first-child {
    opacity: 0;
    animation: cssload-loading-ani2 0.58s linear infinite;
    -o-animation: cssload-loading-ani2 0.58s linear infinite;
    -ms-animation: cssload-loading-ani2 0.58s linear infinite;
    -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
    -moz-animation: cssload-loading-ani2 0.58s linear infinite;
    transform: translate(-19px);
    -o-transform: translate(-19px);
    -ms-transform: translate(-19px);
    -webkit-transform: translate(-19px);
    -moz-transform: translate(-19px);
}

.cssload-loading i:nth-child(2),
.cssload-loading i:nth-child(3) {
    animation: cssload-loading-ani3 0.58s linear infinite;
    -o-animation: cssload-loading-ani3 0.58s linear infinite;
    -ms-animation: cssload-loading-ani3 0.58s linear infinite;
    -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
    -moz-animation: cssload-loading-ani3 0.58s linear infinite;
}

.cssload-loading i:last-child {
    animation: cssload-loading-ani1 0.58s linear infinite;
    -o-animation: cssload-loading-ani1 0.58s linear infinite;
    -ms-animation: cssload-loading-ani1 0.58s linear infinite;
    -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
    -moz-animation: cssload-loading-ani1 0.58s linear infinite;
}




@keyframes cssload-loading-ani1 {
    100% {
        transform: translate(39px);
        opacity: 0;
    }
}

@-o-keyframes cssload-loading-ani1 {
    100% {
        -o-transform: translate(39px);
        opacity: 0;
    }
}

@-ms-keyframes cssload-loading-ani1 {
    100% {
        -ms-transform: translate(39px);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loading-ani1 {
    100% {
        -webkit-transform: translate(39px);
        opacity: 0;
    }
}

@-moz-keyframes cssload-loading-ani1 {
    100% {
        -moz-transform: translate(39px);
        opacity: 0;
    }
}

@keyframes cssload-loading-ani2 {
    100% {
        transform: translate(19px);
        opacity: 1;
    }
}

@-o-keyframes cssload-loading-ani2 {
    100% {
        -o-transform: translate(19px);
        opacity: 1;
    }
}

@-ms-keyframes cssload-loading-ani2 {
    100% {
        -ms-transform: translate(19px);
        opacity: 1;
    }
}

@-webkit-keyframes cssload-loading-ani2 {
    100% {
        -webkit-transform: translate(19px);
        opacity: 1;
    }
}

@-moz-keyframes cssload-loading-ani2 {
    100% {
        -moz-transform: translate(19px);
        opacity: 1;
    }
}

@keyframes cssload-loading-ani3 {
    100% {
        transform: translate(19px);
    }
}

@-o-keyframes cssload-loading-ani3 {
    100% {
        -o-transform: translate(19px);
    }
}

@-ms-keyframes cssload-loading-ani3 {
    100% {
        -ms-transform: translate(19px);
    }
}

@-webkit-keyframes cssload-loading-ani3 {
    100% {
        -webkit-transform: translate(19px);
    }
}

@-moz-keyframes cssload-loading-ani3 {
    100% {
        -moz-transform: translate(19px);
    }
}