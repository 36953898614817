.plan_form_image {
  .profile .profile-cover.-edited img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}
.profile{
  position: relative;
  .mypage & {
    background-color: #fff;
    @include bkp(pc){
      padding-top: 8px;
      padding-bottom: 40px;
    }
    @include bkp(sp){
      // padding-top: 30px;
    }
  }
  .profile-main {
    margin: 0 auto;
    padding: 32px 0 0;
    width: 100%;
    .mypage & {
      max-width: 1120px;
      margin: 32px auto 0;
      padding: 0 24px;
      @include bkp(sp){
        padding: 0 16px;
        margin: 16px auto 32px;
      }
    }
  }
  .profile-cover{
    position: relative;
    max-width: 700px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    overflow: hidden;
    margin: 0 auto;
    .header-image & {
      max-width: max-content;
      margin: 0;
    }
    @media screen and (max-width: 639px) {
      max-height: 364px;
    }
    @media screen and (max-width: 567px) {
      max-height: 323px;
    }
    @media screen and (max-width: 479px) {
      max-height: 273px;
    }
    @media screen and (max-width: 374px) {
      max-height: 213px;
    }
    @media screen and (max-width: 319px) {
      max-height: 181px;
    }
    &.-edited {
      background: transparent;
      width: 100%;
      max-width: 100vw;
      .edit {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 100%;
      }
      img {
        width: 472px;
        height: 269px;
        max-width: 472px;
        max-height: 269px;
        @media screen and (max-width: 480px) {
          width: 100%;
          height: auto;
          object-fit: cover;
          max-width: none;
          max-height: none;
          aspect-ratio: 128/73;
        }
      }
      .header-image-cover {
        background-color: #bbb;
      }
    }
    &.-header {
      &:before {
      content:"";
        display: block;
        padding-top: 57.145%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .header-image-cover {
      margin: 0;
      display: flex;
      overflow: hidden;
      min-height: 100px;
      align-items: center;
      line-height: 0;
      justify-content: center;
      max-width: 100vw;
      width: 100%;
    }
    .blank-header-image {
      width: 100%;
      height: 100px;
    }
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
    .edit {
      position: absolute;
      z-index: 1;
      &.-uploaded {
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: white;
        }
      }
    }
    .uploadBtn {
      &:hover {
        background-color: transparent;
      }
    }
    .deleteBtn {
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
    }
  }
  .profile-user{
    padding: 0 15px;
    display: flex;
    position: relative;
    .mypage & {
      padding: 0;
      @include bkp(pc){
        max-width: 764px;
        margin: 0 auto;
      }
    }
    .icon {
      position: relative;
      display: block;
      box-sizing: border-box;
      border-radius: 100%;
      border: 2px solid #fff;
      @include bkp(pc){
        min-width: 80px;
        width: 80px;
        height: 80px;
      }
      @include bkp(sp){
        width: 72px;
        height: 72px;
        min-width: 72px;
      }
      .edit{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 76px;
        height: 76px;
        z-index: 100;
      }
    }
    .username{
      font-size: 14px;
      color: #666;
      font-weight: normal;
      margin: 0;
    }
    .title{
      padding: 10px;
      @include bold;
      font-size: 16px;
      color: $text-strong-color;
    }
  }
  .profile-detail{
    padding:24px;
    @include bkp(sp){
      padding: 16px 0;
    }
    .description{
      font-size: 12px;
      color: $text-base-color;
    }
  }
  .profile-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    .status{
      display: flex;
      li{
        padding-right: 10px;
        font-size: 12px;
        color: $text-notice-color;
        strong{
          font-size: 14px;
          color: $link-color;
        }
      }
    }
  }
  .profile-introduce {
    width: 100%;
    @include bkp(sp){
      display: flex;
      flex-direction: column;
    }
    .profile-introduce__inner {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      @include bkp(sp){
        margin-left: 16px;
      }
    }
    .profile-introduce__item {
      &.-top {
        display: flex;
        align-items: center;
        @include bkp(pc){
          margin-bottom: 16px;
        }
      }
      &.-bottom {
        @include bkp(pc){
          margin-left: 104px;
        }
      }
    }
    .title {
      padding: 0;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
      @include bkp(sp){
        font-size: 16px;
      }
      svg {
        margin: 0;
        padding: 0;
        height: 32px;
        width: 32px;
        flex-shrink: 0;
      }
    }
    .profile-detail {
      padding: 0;
      margin: 0 0 24px;
      clear: both;
      dl {
        display: inline-flex;
        align-items: center;
        & + dl {
          margin-left: 24px;
        }
        dt {
          order: 2;
          font-weight: normal;
          font-size: 14px;
          color: #666;
        }
        dd {
          margin-right: 5px;
          order: 1;
          font-weight: 500;
          font-size: 16px;
        }
      }
      .description {
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        @include bkp(pc){
          margin: 0 0 24px;
        }
        @include bkp(sp){
          margin: 16px 0 0;
        }
      }
      .profile-detailInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include bkp(sp){
          margin: 32px 0;
        }
      }
    }
    .social-btns-wrapper {
      &.-dialog {
        justify-content: center;
        .social-btns li a {
          height: 36px;
          width: 36px;
          border-radius: 36px;
          &.twitter {
              background: #1D9BF0;
          }
          &.line {
              background: #4CC764;
          }
          &.clipboard {
              background: #b2b2b2;
          }
        }
      }
    }
  }
  .displayMember {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include bkp(sp){
      margin-bottom: 32px;
    }
    a {
      color: #333;
    }
    dt {
      order: 1;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
    }
    dd {
      margin: 0;
      order: 2;
      font-size: 20px;
      line-height: 1;
      font-weight: bold;
      span {
        font-size: 48px;
      }
    }
  }
  .free-trial-period {
    align-content: center;
    display: flex;
    justify-content: center;
    color: #f5ad42;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
.personal_address_multiline_text {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    padding: 13px 7px;
  }
}
