.swiper-wrapper {
    display: flex;
    align-items: center;
}

.swiper-container-banner {
    @media #{$sp} {
    }
    .swiper-slide {
        opacity: 0.4;
        .thumb {
            a {
                @media #{$sp} {
                    padding: 0px 30px;
                }
            }
        }
    }
    .swiper-slide-active {
        opacity: 1;
    }
    .swiper-slide-next {
        opacity: 1;
    }
    .swiper-banner-pagination {
        margin-top: 8px;
        text-align: center;
        @media #{$sp} {
        }
    }
    .swiper-button-next {
        right: -28px;
        margin-top: -30px;
        @media #{$sp} {
            margin-top: -30px;
            right: 0;
        }
        &:after {
            font-size: 28px;
            font-weight: bold;
            @media #{$sp} {
                font-size: 18px;
            }
        }
    }
    .swiper-button-prev {
        left: -28px;
        margin-top: -30px;
        @media #{$sp} {
            margin-top: -30px;
            left: 0;
        }
        &:after {
            font-size: 28px;
            font-weight: bold;
            @media #{$sp} {
                font-size: 18px;
            }
        }
    }
}

.swiper-pagination {
    bottom: -28px !important;
    left: 0 !important;
    display: flex;
    gap: 12px;
    @media #{$sp} {
        &.pagination-sp {
            gap: 8px;
            justify-content: center;
        }
    }
    .swiper-pagination-bullet {
        width: 32px;
        height: 10px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.5);
        opacity: 1;
        @media #{$sp} {
            background: #d9d9d9 !important;
        }
    }
    .swiper-pagination-bullet-active {
        background: #1f1f1f !important;
    }
}
