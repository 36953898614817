.intro_movie_wrap {
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  .intro_movie {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.mypage {
  .MuiTabScrollButton-root {
    display: none;
  }
  &.container {
    max-width: none;
    background: #fff;
  }

  .form-block {
    &.-post {
      border: 1px solid #e7edf0;
      border-radius: 6px;
      padding: 15px 20px 25px;
      margin: 0 40px;
    }
  }

  .clearfix::after {
    content: ' ';
    display: block;
    clear: both;
  }

  .contents {
    width: 100%;
    max-width: 736px;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    margin: 0 auto;
    background-color: inherit;
    border-radius: inherit;
    overflow: none;
    border-top: inherit;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    border: 0;
    &.-nOwner {
      max-width: 1120px;
    }
  }
  .introductionContainer {
    .introductionSection {
      &.firstSection {
        .introductionImage {
          margin-top: 0;
        }
      }
      &:last-of-type {
        margin-bottom: 24px;
      }
      &.is-hidden {
        margin-bottom: 8px;
        &.firstSection {
          @include bkp(sp) {
            max-height: 500px;
            position: relative;
            overflow: hidden;
            &:before {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 56px;
              background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.5046393557422969) 50%,
                rgba(255, 255, 255, 0) 100%
              );
            }
          }
        }
        &:not(.firstSection) {
          @include bkp(sp) {
            display: none;
          }
        }
      }
    }
    .introductionImage {
      margin: 16px 0;
      text-align: center;
    }
    .introductionText {
      font-size: 16px;
      line-height: 1.6;
      white-space: pre-line;
      word-wrap: break-word;
    }
    .readmore {
      cursor: pointer;
      text-align: center;
      text-decoration: underline;
      @include bkp(pc) {
        display: none;
      }
      @include bkp(sp) {
        display: block;
        margin: 32px 0;
      }
    }
    .introductionFooter {
      text-align: center;
      margin-top: 72px;
      @include bkp(sp) {
        margin-top: 48px;
      }
      .introductionFooter__banner {
        width: 85%;
        vertical-align: bottom;
        @include bkp(sp) {
          width: 100%;
        }
      }
    }
  }

  .door-grid {
    display: flex;
    @include bkp(pc) {
      justify-content: space-between;
      max-width: 1120px;
      margin: 32px auto 0;
      padding: 0 24px;
    }
    @media screen and (max-width: 846px) {
      flex-wrap: wrap;
    }
    @include bkp(sp) {
      flex-direction: column;
    }
    .door-grid__item {
      &.-left {
        @media screen and (min-width: 847px) {
          max-width: 636px;
          min-width: 380px;
          margin-right: 32px;
          flex-grow: 2;
        }
        @media screen and (max-width: 846px) {
          min-width: 100%;
        }
      }
      &.-right {
        @media screen and (min-width: 847px) {
          max-width: 380px;
        }
        @media screen and (max-width: 846px) {
          width: 100%;
          padding: 48px 16px 32px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .door-grid-heading {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 3px solid #11b5f4;
        padding-bottom: 3px;
        margin-bottom: 16px;
      }
    }
  }

  .tabs {
    padding-bottom: 1px;
    border-bottom: 1px solid #f2f2f2;
    &.-colmun3 {
      .door-detail-tab {
        min-width: auto;
        border-radius: 0;
        width: 140px;
      }
    }
    .MuiTabs-flexContainer {
      @include bkp(pc) {
        width: 1120px;
        margin: 0 auto;
        padding: 0 24px;
        justify-content: flex-start;
      }
      @include bkp(sp) {
        padding: 0;
      }
    }
  }

  .door-tab-panel {
    width: 100%;
    max-width: 636px;
    background-color: #fff;
    @media screen and (max-width: 846px) {
      max-width: none;
    }
    @media #{$sp} {
      padding: 20px 15px;
    }
    .posts > li {
      padding: 15px 0 20px;
      border-bottom: 2px solid #d7dcdf;
      &:last-of-type {
        border-bottom: 0;
      }
      .text {
        font-size: 14px;
      }
    }
    .post {
      textarea {
        padding: 0;
        border: 0;
        height: auto;
        min-height: 80px;
        line-height: 1.6;
        word-break: break-all;
      }
    }
    &.-post {
      padding: 40px 0 0;
      @media #{$sp} {
        padding: 20px 0 0;
      }
      .posts > li {
        padding: 24px 40px 12px;
        @media #{$sp} {
          padding: 24px 15px 12px;
        }
      }
    }
  }

  .door-side-card {
    margin: 0;
    padding: 32px 24px;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    > {
      h3 {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;

        > svg {
          width: 24px;
          margin: 0 10px 0 0;
          flex-shrink: 0;
        }
      }

      h4 {
        margin: 15px 0;
        font-size: 14px;
      }
    }
  }

  .door-register {
    text-align: center;
    padding: 0;
    margin: 0;
    &.-sideber {
      position: static;
      padding: 32px 20px 0;
      margin-top: 0;
    }
    button {
      &.subscribed {
        background: #45b5f5;
      }
    }
  }

  .currency {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;

    > span {
      font-size: 25px;
    }
  }

  .door-detail-tab {
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    min-width: 152px;
    height: 48px;
    border-radius: 0;
    text-transform: none;
    opacity: 1;
    padding: 0;
    &:hover {
      background: #f2f2f2;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .MuiTabs-indicator {
    background-color: #11b5f4;
    height: 3px;
  }
}

@include bkp(pc) {
  .hide-pc {
    display: none !important;
  }
}
@include bkp(sp) {
  .hide-mobile {
    display: none;
  }

  .footer-btn {
    position: fixed;
    width: 160px;
    bottom: 30px;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 1000;
    border-radius: 30px 0 0 30px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: bold;
    z-index: 3;
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
.input-controlText.counterText textarea {
  padding-bottom: 10px;
}
