
* {
  font-family: "Noto Sans JP", sans-serif;
  box-sizing: border-box;
}

img,
svg {
  vertical-align: bottom;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  word-break: break-word;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
