.container.-management {
    .inner {
        width: 1120px;
        margin: auto;
    }
}
.contents.-management {
    width:1120px;
    @media only screen and (max-width: 640px) {
        width: 100%;
        padding: 0 20px;
    }
    .contents-header {
        margin-bottom: 48px;
    }
    .contents-body {
        padding: 64px 0 70px;
        width: 800px;
        margin: auto;
        @media only screen and (max-width: 640px) {
            width: 100%;
            padding: 24px 0 64px;
        }
    }
    .form-block {
        display: flex;
        align-items: flex-start;
        padding: 0;
        margin-bottom: 56px;
        @media only screen and (max-width: 640px) {
            align-items: normal;
            flex-direction: column;
            margin-bottom: 40px;
        }
        .inputWrap {
            width: 100%;
        }
        .form-wrap {
            position: relative;
            .counter {
                position: absolute;
                bottom: 5px;
                right: 8px;
                color: rgba(136, 136, 136, 0.86);
                font-size: 13px;
                // @media only screen and (max-width: 640px) {
                //     position: static;
                //     bottom: 0;
                //     right: 0;
                //     text-align: right;
                //     margin-top: 6px;
                // }
            }
        }
        .input-label {
            font-size: 14px;
            margin: 0;
            width: 200px;
            flex-shrink: 0;
            @media only screen and (max-width: 640px) {
                width: 100%;
                margin-bottom: 8px;
            }
        }
        .input-controlText {
            .MuiOutlinedInput-multiline {
                min-height: 56px;
                padding: 13px 6px 25px;
            }
        }
        .input-control input{
            padding: 20px 6px 45px;
        }
        .notice {
            margin-top: 4px;
            font-size: 13px;
            color: #333;
            svg {
                color: #ffca00;
                font-size: 18px !important;
                width: 18px !important;
                vertical-align: bottom;
            }
        }
        .header-image {
            width: 100%;
            .edit {
                width: 50px;
                height: 50px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1000;
                svg {
                    fill: white;
                }
            }
        }
        .set-money {
            font-size: 21px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 640px) {
                margin-left: 0;
            }
            span {
                font-size: 13px;
                margin-left: 5px;
            }
        }
        .upload-image {
            .btn-upload {
                margin: 0 auto;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 90px;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
                background-color: #F0F0F0;
                color: #A2A2A2;
                @media only screen and (max-width: 640px) {
                    height: 50px;
                }
                svg {
                    margin-right: 6px;
                    fill: #A2A2A2;
                }
                .MuiIconButton-root:hover {
                    background-color:transparent;
                }
            }
        }
    }
    hr {
        margin: 64px 0;
    }
    .btn-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 640px) {
            flex-direction: column;
        }
    }
    .btn {
        width: 100%;
        height: 50px;
        & + .btn {
            margin-left: 20px;
            @media only screen and (max-width: 640px) {
                margin-left: 0;
                margin-top: 16px;
            }
        }
        &.btn-preview {
            background: white;
            border: 1px solid #28B5F5;
            color: #28B5F5;
            &:hover {
                background: #28B5F5;
                color: white;
            }
        }
    }
}
.member-status {
    display: flex;
    align-items: center;
    font-size: 12px;
    .MuiFormControlLabel-root {
        margin-left: 8px;
        margin-right: 8px;
    }
    .hide {
        display: block;
    }
}

.tabs {
    .tabs__item {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
    }
}

/* 参加中のサロン画面用 */
.-management2 {
    .breadcrumbs {
        max-width: 1040px;
        margin: 0 auto;
    }
    .contents {
        border: none;
        .contents-header {
            max-width: 1040px;
            margin: 0 auto 40px;
            padding: 16px 0 0;
            @media #{$sp} {
                max-width: none;
                padding: 0;
                margin-bottom: 16px;
            }
            h1 {
                font-size: 32px;
                @media #{$sp} {
                    font-size: 24px;
                }
            }
        }
        .doorThumbnail {
            display: flex;
            align-items: center;
            overflow: hidden;
            flex-shrink: 0;
            img {
                width: 300px;
                height: 170px;
                object-fit: cover;
                vertical-align: bottom;
                @media #{$sp} {
                    width: 100%;
                    height: 196px;
                }
            }
        }
        .doorInfoWrap {
            @media #{$sp} {
                padding: 16px;
            }
        }
        .doorTitle {
            font-size: 17px;
            margin-bottom: 8px;
            @media #{$sp} {
                font-size: 14px;
                margin-bottom: 16px;
            }
        }
        .planName {
            line-height: 1.5;
            font-weight: normal;
            font-size: 14px;
            @media #{$sp} {
                font-size: 14px;
                margin-bottom: 16px;
            }
        }
        .userId {
            position: relative;
            font-weight: normal;
            color: initial;
            margin-bottom: 16px;
            font-size: 14px;
            @media #{$sp} {
                font-size: 12px;
            }
            a {
                display: flex;
                align-items: center;
                gap: 8px;
            }
            .icon {
                display: none;
                @media only screen and (max-width: 640px) {
                    display: block;
                    flex-shrink: 0;
                }
            }
        }
        .userName {
            font-size: 12px;
        }
        .period {
            font-size: 12px;
            @media #{$sp} {
                font-size: 14px;
            }
        }
		.btn {
			width: 160px;
            height: 32px;
            @media only screen and (max-width: 640px) {
                width: 100%;
                height: 44px;
                font-size: 14px;
            }
			& + .btn{
                margin-top: 16px;
                @media #{$sp} {
                    margin-top: 16px;
                }
			}
		}
        .userInfo {
            display: flex;
            justify-content: space-between;
            gap: 24px;
            .userInfo__item {
                @media only screen and (max-width: 640px) {
                    margin: 0;
                }
                &.-left {
                    display: flex;
                    gap: 32px;
                    margin-right: 16px;
                    @media #{$sp} {
                        display: block;
                        gap: 0;
                        margin-right: 0;
                    }
                }
                &.-right {
                    flex-grow: unset;
                    @media only screen and (max-width: 640px) {
                        padding: 16px;
                    }
                }
            }
        }
        .subscriptionList {
            @media #{$pc} {
                padding: 0 16px;
            }
            .userInfo {
                @media only screen and (max-width: 640px) {
                    display: block;
                    box-shadow: 0px 4px 10px #00000029;
                }
            }
            .subscriptionList__item {
                padding: 24px 0;
                @media #{$sp} {
                    padding: 16px;
                }
                & + .subscriptionList__item{
                    border-top: 1px solid #D7DCDF;
                    @media #{$sp} {
                        border: 0;
                    }
                }
            }
        }
    }
    .contents, .inner {
        width: 80vw;
        @media only screen and (max-width: 640px) {
            width: 100%;
        }
    }
}
