.commentWrap {
	padding: 24px 15px;
	margin: 0 auto;
	max-width: 726px;
	justify-content: center;
	@media screen and (max-width: 846px) {
		padding: 24px;
	}
	@include bkp(sp){
		padding: 12px 16px;
	}
	& + .commentWrap {
		padding-top: 0;
	}
}

.commentList {
	width: 100%;
	.commentList__item {
		& + .commentList__item {
			padding-top: 16px;
			border-top: 1px solid #ddd;
			@include bkp(sp){
				padding-top: 12px;
			}
		}
	}
}

.commentListBottom {
	border-top: 1px solid #ddd;
	padding: 16px 0;
}

.comment {
	display: flex;
	&.-commentInput {
		width: 100%;
		border-bottom: 1px solid #ddd;
	}
	.comment__item {
		&.-left {
			display: flex;
			align-items: center;
			flex-direction: column;
			flex-shrink: 0;
			flex-basis: 48px;
			flex-grow: 0;
		}
		&.-right {
			margin-left: 8px;
			width: 100%;
			@include bkp(sp){
				overflow-x: hidden;
			}
		}
	}
	.icon {
		width: 48px;
		height: 48px;
		.MuiAvatar-root {
			width: 48px;
			height: 48px;
		}
		img {
			border-radius: 100%;
			max-width: 100%;
			min-height: 100%;
			height: auto;
		}
	}
	.line {
		width: 2px;
		background-color: #ddd;
		flex-grow: 1;
	}
	.name {
		font-weight: 500;
		line-height: 20px;
		font-size: 15px;
		margin-bottom: 0;
	}
	.ownerId {
		font-size: 12px;
		color: #666;
		margin-right: 8px;
		line-height: 1.3;
	}
	.date {
		font-size: 12px;
		color: #666;
		line-height: 1.3;
	}
	.commentHeading {
		margin-bottom: 8px;
		.owner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.owner__item {
				line-height: 1;
				&.-left {
					display: flex;
					flex-direction: column;
					a {
						color: #333;
					}
				}
				&.-right {

				}
				.owner__itemInner {
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	}
	.commentBody {
		margin-bottom: 24px;
		@include bkp(sp){
			margin-bottom: 12px;
		}
		p {
			font-size: 16px;
		}
		.media {
			img {
				width: 100%
			}
		}
		a {
			color: #45B5F5;
		}
		.disabled_email_link {
			cursor: auto;
			color: inherit!important;
			pointer-events: none;
		}
	}
	.commentFooter {
		margin-bottom: 16px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		@include bkp(sp){
			margin-bottom: 16px;
		}
		.commentFooter__item {
			display: flex;
			align-items: center;
			line-height: 1;
			& + .commentFooter__item {
				margin-top: 16px;
				@include bkp(sp){
					margin-top: 12px;
				}
			}
			.likeCount {
				color: #888;
				font-weight: bold;
				margin-right: 24px;
				@include bkp(sp){
					margin-right: 16px;
				}
				svg {
					fill: #b2b2b2;
				}
			}
			.like {
				display: flex;
				align-items: center;
				cursor: pointer;
				font-weight: bold;
				border: 0;
				margin-right: 8px;
				color: #888;
				gap: 4px;
				&.liked {
					color: #e0245e
				}
			}
			.toComment {
				cursor: pointer;
				font-weight: bold;
				border: 0;
				color: #888;
			}
			.reply {
				border: 0;
				padding: 0;
				margin: 0;
				color: #28B3F8;
				cursor: pointer;
			}
		}
	}
}
