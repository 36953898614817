.btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.-row {
    flex-direction: row;
    @include bkp(sp){
      flex-wrap: wrap;
    }
    .btnWrap__item {
      & + .btnWrap__item {
        @include bkp(pc){
          margin-top: 0;
          margin-left: 32px;
        }
        @include bkp(sp){
          margin-top: 16px;
        }
      }
    }
  }
  &.-rowRight {
    justify-content: flex-end;
    .btnWrap__item {
      & + .btnWrap__item {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }
  .btnWrap__item {
    & + .btnWrap__item {
      margin-top: 32px;
    }
  }
}
.btn {
  font-size: 14px;
  font-weight: bold;
  background: #26bfdb;
  color: #fff;
  height: 48px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  line-height: 48px;
  flex-shrink: 0;
  border: 0;
  cursor: pointer;
  @media #{$sp} {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
  }
  &:hover {
    opacity: 0.9;
    color: #fff;
  }
  &.btn-inline {
    display: inline-flex;
  }
  &.btn-static {
    @media #{$sp} {
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
    }
  }
  &.is-disable {
    background: #999;
  }
  & + .btn,
  & + .textLink {
    margin-top: 32px;
    @media #{$sp} {
      margin-top: 24px;
    }
  }
  &.clicked {
		background: #ccc;
		color: #fff;
		&:hover {
			opacity: 1;
		}
  }
  &.btn-secondary {
    background: #45B5F5;
  }
  &.btn-block {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  &.btn-register {
    background: #FC665C;
		color: #fff;
    width: 304px;
    height: 54px;
    font-size: 18px;
  }
  &.btn-planJoin {
    background: #FC665C;
		color: #fff;
  }
  &.btn-submit {
    background: #F5AD42;
		color: #fff;
  }
	&.btn-keywordSelect {
		border: 1px solid #cbcbcb;
		background: #fff;
		color: #333;
		font-size: 13px;
		border-radius: 5px;
		height: 54px;
		&.btn svg {
				color: #333;
		}
	}
	&.btn-contact {
		background: #fff;
		color: #333;
    border: 1px solid #333;
		&:hover {
			opacity: 1;
		}
	}
	&.btn-danger {
		background: #FF6D6D;
		color: #fff;
	}
	&.btn-disabled {
		background: #ccc;
		color: #fff;
		&:hover {
			opacity: 1;
		}
	}
	&.btn-grayout {
		background: #707070;
		color: #fff;
		&:hover {
			opacity: .8;
		}
	}
	&.btn-border {
		background: #fff;
		color: #707070;
    border: 1px solid #707070;
		&:hover {
			opacity: .8;
		}
	}
  &.btn-top {
		background: #fff;
		color: #26bfdb;
    border: 1px solid #26bfdb;
		&:hover {
			opacity: .8;
		}
	}
  &.btn-border-danger {
    @extend .btn-border;
    color: #FF6D6D;
    border-color: #FF6D6D;
  }
  &.btn-sns {
    font-size: 14px;
    width: 100%;
    height: 56px;
    justify-content: left;
    margin-top: 10px;
    @media #{$sp} {
      height: 40px;
    }
    &.btn-sns {
      @media #{$sp} {
        margin-top: 10px;
      }
    }
    &.-border {
      border: 1px solid #707070;
      color: #121212;
      background: transparent;
    }
    &.-twitter {
      background: #3A3B43;
      color: #fff;
    }
    &.-facebook {
      background: #435994;
      color: #fff;
    }
    &.-line {
      background: #06C755;
      color: #fff;
    }
    &.-signin {
      padding-left: 27%;
    }
    &.-signup {
      padding-left: 30%;
    }
  }
  // コメント投稿ボタン用
  &.btn-comment {
    background: #26bfdb;
    border-radius: 4px;
    width: 74px;
    height: 29px;
    line-height: 29px;
    font-size: 13px;
    &.btn-disabled,
    &:disabled {
      background: #28B3F8;
      color: #ffffff;
      opacity: 0.5;
    }
  }
	&.btn-xs {
		height: 32px;
		padding: 0 16px;
		line-height: 32px;
		font-size: 12px;
	}
	&.btn-size1{
		width: 200px;
	}
	&.btn-size2{
		width: 240px;
	}
	&.btn-size3{
		width: 280px;
	}
	&.btn-size4{
		width: 320px;
	}
	&.btn-size5{
		width: 360px;
	}
}
.textLink {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  -webkit-appearance: none;
  &.is-link {
    color: #26bfdb;
  }
  &.is-alert {
    color: #ff6d6d;
  }
  &.is-grayedout {
    color: #999999;
  }
  &.text-sm {
    font-size: 12px;
  }
  &.text-xs {
    font-size: 10px;
  }
}

.bankButton {
  font-size: 16px;
  font-weight: bold;
  background: #26bfdb;
  color: #fff;
  height: 50px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  line-height: 50px;
  flex-shrink: 0;
  border: 0;
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  &.cancel {
    margin-bottom: 10px;
    background: #78828a;
    &:hover {
      background: lighten(#78828a, 5);
    }
  }
  &:hover {
    background: lighten(#26bfdb, 5);
  }
}
