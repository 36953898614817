$contents-width:960px;
$bg-sub-color:#f1f8fd;
$shadow-style: 0 2px 12px 6px rgba(0, 0, 0, .04);
$register-color:#ffae28;
$inquiry-color:#505e74;

.futureplan {
    background: #fff;
    hr {
        margin: 15px;
    }
    .futureplan-header {
        background: #11b3f3;
        text-align: center;
        padding: 64px 0;
        @media #{$sp} {
            padding: 32px 0;
        }
        h1 {
            color: #fff;
            font-size: 46px;
            margin-bottom: 32px;
            @media #{$sp} {
                font-size: 25px;
                margin-bottom: 16px;
            }
        }
        .description {
            color: #fff;
            font-size: 16px;
            @media #{$sp} {
                font-size: 13px;
            }
        }
    }
    .futureplan-base {
        padding: 24px 0;
        @media #{$sp} {
            padding: 20px 18px;
        }
        .futureplan-inner {
            width: 960px;
            margin: 0 auto;
            @media #{$sp} {
                width: 100%;
            }
        }
        h2 {
            font-size: 40px;
            padding-bottom: 23px;
            margin-bottom: 23px;
            border-bottom: 2px solid #11b3f3;
            @media #{$sp} {
                font-size: 20px;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
            &.-boderless {
                border-bottom: 0;
            }
        }
        .section-start {
            margin-top: 76px;
        }
    }
    .futureplan-hero {
        margin-bottom: 40px;
        @media #{$sp} {
            margin-bottom: 24px;
        }
    }
    .futureplan-release {
        font-size: 16px;
        @media #{$sp} {
            font-size: 14px;
        }
        h3 {
            font-size: 24px;
            color: #4081dc;
            margin-bottom: 32px;
            @media #{$sp} {
                font-size: 21px;
                margin-bottom: 16px;
            }
        }
        ul {
            list-style: '・';
            margin-left: 15px;
            li {
                & + li {
                    margin-top: 15px;
                }
            }
        }
    }
    .futureplan-list {
        font-size: 16px;
        line-height: 1.66;
        list-style: '・';
        margin-left: 15px;
        @media #{$sp} {
            font-size: 14px;
            line-height: 1.78;
        }
    }
}
.pagetop{
    &.-futureplan {
        @media #{$sp} {
            display: none;
        }
    }
}
