$contents-width: 960px;
$bg-sub-color: #f1f8fd;
$shadow-style: 0 2px 12px 6px rgba(0, 0, 0, 0.04);
$register-color: #ffae28;
$inquiry-color: #505e74;

.guideline {
  background: #fff;
  // margin-top: 70px;
  // @media (max-width: 1280px) {
  //   margin-top: 60px;
  // }
  @media (max-width: 480px) {
    margin-top: 47px;
  }
  .guideline-header {
    background: url(../images/guideline/bg_header_pc.svg) #11b3f3 no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media #{$sp} {
      background: url(../images/guideline/bg_header_mobile.svg) #11b3f3
        no-repeat;
      background-size: cover;
      background-position: center;
      padding: 32px 0;
      height: 130px;
    }
    h1 {
      color: #fff;
      font-size: 32px;
      margin-bottom: 12px;
      font-weight: normal;
      @media #{$sp} {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
    .description {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      @media #{$sp} {
        font-size: 20px;
      }
    }
  }
  .guideline-body {
    padding-bottom: 130px;
    @media #{$sp} {
      padding-bottom: 60px;
    }
  }
  .guideline-base {
    padding: 40px 0;
    font-size: 15px;
    @media #{$sp} {
      font-size: 13px;
      padding: 20px 18px;
    }
    .guideline-inner {
      width: 960px;
      margin: 0 auto;
      @media #{$sp} {
        width: 100%;
      }
    }
    h2 {
      font-size: 32px;
      margin-bottom: 24px;
      color: #ffae28;
      @media #{$sp} {
        font-size: 22px;
        margin-bottom: 12px;
      }
    }
  }
  .guideline-list {
    margin-top: 64px;
    @media #{$sp} {
      margin: 32px 0;
    }
    li {
      & + li {
        margin-top: 64px;
      }
    }
  }
  .guideline-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    @media #{$sp} {
      align-items: normal;
    }
    .text {
      h3 {
        font-size: 20px;
        margin-bottom: 24px;
        @media #{$sp} {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
      p {
        font-size: 15px;
        line-height: 1.5;
        @media #{$sp} {
          font-size: 13px;
        }
      }
    }
    .icon {
      margin-right: 32px;
      @media #{$sp} {
        margin-right: 16px;
        img {
          width: 96px;
          height: 96px;
        }
      }
    }
  }
  .paragraph {
    & + .paragraph {
      margin-top: 20px;
    }
  }
  .underline {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
// .pagetop{
//     &.-guideline {
//         @media #{$sp} {
//             display: none;
//         }
//     }
// }
