@use "sass:math";

@keyframes HiddenAnimation {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(-100px);
	}
}

@keyframes VisibleAnimation {
	from {
		opacity: 0;
		transform: translateY(-100px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.commonHeading {
	color: #333;
	position: relative;
	margin-bottom: 40px;

	@media screen and (max-width: 846px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	&.flexible {
		@media screen and (max-width: 846px) {
			margin-top: 0;
		}
	}

	.firstParagraph {
		margin-top: 20px;

		@media screen and (max-width: 846px) {
			margin-top: 0;
		}
	}

	h2 {
		font-size: 22px;
		margin-bottom: 8px;

		@media screen and (max-width: 846px) {
			font-size: 20px;
		}
	}

	.discription {}

	.btn {
		position: absolute;
		top: 0;
		right: 0;

		@media screen and (max-width: 846px) {
			display: none;
		}
	}
}

.room {
	height: 100%;

	.header {}

	.doorImage {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		max-width: 320px;
		max-height: 183px;

		img {
			// min-width: 100%;
			// height: auto;
			object-fit: cover;
			max-width: 320px;
			max-height: 183px;
			width: 320px;
			height: 183px;

			@include bkp(sp) {
				max-width: 290px;
				max-height: 162px;
				width: 290px;
				height: 162px;
			}
		}
	}

	.doorName {
		font-size: 16px;
		font-weight: bold;
		padding: 16px;

		@media screen and (max-width: 846px) {
			font-size: 12px;
			font-weight: normal;
		}
	}

	.search,
	.home, 
	.bookmark,
	.scratch {
		font-size: 14px;
		margin-bottom: 8px;
		padding: 4px 16px 4px 12px;

		&.is-select {
			background: #E8E8E8;
		}

		a {
			display: flex;
			align-items: center;
			color: #333333;
		}

		img {
			margin-right: 8px;
		}

		@media screen and (max-width: 640px) {
			margin-top: 15px;
		}
	}

	.bookmark {
		margin-bottom: 8px;
	}
	.scratch {
		margin-bottom: 40px;
		@media screen and (max-width: 640px) {
			margin-bottom: 32px;
		}
	}

	.search {
		@media screen and (max-width: 640px) {
			margin-top: 16px;
			padding: 4px 16px 0px 12px;

			svg {
				margin-right: 8px;
			}
		}
	}

	.roomAdd {
		display: flex;
    align-items: center;
		padding: 0 16px;
		margin-bottom: 24px;
		a {
			color: #333;
			font-size: 14px;
			font-weight: normal;
			line-height: 1;
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		span {
			font-size: 20px;
			padding-right: 10px;
		}
	}

	.roomAddWrapper {
		@media screen and (max-width: 846px) {
			padding-bottom: 50px;
		}
	}

	.roomList {
		@media screen and (min-width: 847px) {
			max-height: 600px;
			overflow: auto;
		}
		@media screen and (max-width: 640px) {
			padding-bottom: 12px;
		}
		.roomList__item {
			font-size: 14px;
			margin-bottom: 8px;
			&:last-of-type {
				margin-bottom: 0;
			}
			a {
				display: flex;
				align-items: center;
				padding: 4px 16px 4px 15px;
				color: #333333;
			}

			img {
				margin-right: 8px;
			}

			&:hover {
				background: #E8E8E8;
			}

			&.is-select {
				background: #E8E8E8;
			}

			&.is-active {
				font-weight: bold;
			}
		}
	}
}

.roomMenu {
	@media screen and (min-width: 847px) {
		width: 100%;
		max-width: 896px;
		position: fixed;
		z-index: 40;
	}

	@media screen and (max-width: 1280px) and (min-width: 960px) {
		max-width: 70%;
	}

	@media screen and (max-width: 960px) and (min-width: 937.4px) {
		max-width: 69%;
	}

	@media screen and (max-width: 937.4px) and (min-width: 914.8px) {
		max-width: 68%;
	}

	@media screen and (max-width: 914.8px) and (min-width: 892.2px) {
		max-width: 67%;
	}

	@media screen and (max-width: 892.2px) and (min-width: 869.6px) {
		max-width: 66%;
	}

	@media screen and (max-width: 869.6px) and (min-width: 847px) {
		max-width: 65%;
	}

	background-color: #fff;

	.roomMenu__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ddd;
		height: 56px;
		font-size: 18px;

		@media screen and (max-width: 846px) {
			height: 49px;
			padding: 0 12px;
			margin-top: -1px;
		}

		// .breadcrumb {
		// 	@media screen and (max-width: 846px) {
		// 		display: none;
		// 	}
		// }
		// .menu .btnPost {
		// 	@media screen and (max-width: 846px) {
		// 		display: none;
		// 	}
		// }
		&.-bookmark{
			border-bottom: none;
		}
	}

	.roomMenu__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 16px;
		border-bottom: 1px solid #ddd;

		// @media screen and (min-width: 847px) {
		// 	display: none;
		// }
		@media screen and (max-width: 321px) {
			overflow: scroll;
		}

		.roomMenu__left {
			height: 28px;
			margin-right: 16px;
		}

		.roomMenu__right {}
	}

	.roomMenu__left {
		display: flex;
		align-items: center;
		font-weight: bold;
		margin-right: 32px;

		@media screen and (max-width: 846px) {
			margin-right: 12px;
		}

		img {
			margin-right: 8px;
		}
	}

	.roomMenu__right {
		flex-shrink: 0;
	}

	.downMenuWrap {
		position: relative;
		margin-left: 8px;
		@media screen and (max-width: 846px) {
			margin-left: 0;
		}
	}

	.downMenu {
		position: absolute;
		width: 404px;
		background: #fff;
		border: 1px solid #ddd;
		font-size: 13px;
		font-weight: normal;
		padding: 12px 24px;
		border-radius: 5px;
		top: 30px;
		left: -182px;
		margin-left: -50%;

		&:before {
			content: "";
			position: absolute;
			top: -16px;
			margin: auto;
			left: 0;
			right: 0;
			width: 0px;
			height: 0px;
			border: 8px solid transparent;
			border-bottom: 8px solid #ddd;
		}

		&:after {
			content: "";
			position: absolute;
			top: -12px;
			margin: auto;
			left: 0;
			right: 0;
			width: 0px;
			height: 0px;
			border: 6px solid transparent;
			border-bottom: 6px solid #fff;
		}

		.roomChangeLink {
			text-align: right;
			text-decoration: underline;

			a {
				color: #333;
			}
		}
	}

	.roomName {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;

		@media screen and (max-width: 846px) {
			font-size: 15px;
			letter-spacing: -0.025em;
		}
	}

	.breadcrumb {
		display: flex;
		align-items: center;

		@media screen and (max-width: 846px) {
			margin-left: 16px;
		}

		.breadcrumb__item {
			display: flex;
			align-items: center;
			font-size: 18px;
			font-weight: bold;
			min-width: 88px;

			&.-divider {
				min-width: auto;
			}

			&:first-child {
				.icon {
					@media screen and (max-width: 846px) {
						display: none;
					}
				}
			}

			&:not(:first-child) {
				@media screen and (max-width: 846px) {
					display: none;
				}
			}

			svg {
				margin-right: 8px;
				flex-shrink: 0;
				@media screen and (max-width: 846px) {
					margin-right: 0;
				}
			}

			.divider {
				margin: 0 8px;
				display: flex;
				align-items: center;

				svg,
				.MuiSvgIcon-root {
					font-size: 15px !important;
					font-size: 1.5rem !important;
					width: 15px !important;
					fill: #888;
					margin-right: 0;
				}
			}
		}
	}

	.menu {
		display: flex;
		align-items: center;
		font-size: 16px;

		.menu__item {
			&+.menu__item {
				margin-left: 24px;

				@media screen and (max-width: 846px) {
					margin-left: 16px;
				}
			}

			&.-btnPost {
				&+.menu__item:nth-child(2) {
					@media screen and (max-width: 846px) {
						margin-left: 0;
					}
				}
			}

			.MuiSvgIcon-root {
				cursor: pointer;
			}
		}

		.icon {
			cursor: pointer;
			width: 28px;
			height: 28px;
			vertical-align: bottom;
		}

		.btnPost {
			font-size: 13px;
			font-weight: bold;
			background: #26bfdb;
			color: #fff;
			height: 28px;
			line-height: 28px;
			padding: 0 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			line-height: 28px;
			flex-shrink: 0;

			&:hover {
				opacity: .9;
				color: #fff;
			}

			@media screen and (max-width: 846px) {
				display: none;
			}
		}
	}

	// 投稿一覧
	&.-postList {
		@media screen and (max-width: 846px) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
		}
	}

	// 投稿詳細
	&.-postDetail {
		@media screen and (max-width: 846px) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 50;

			&.-visible {
				animation: VisibleAnimation 0.5s forwards;
			}

			&.-hidden {
				animation: HiddenAnimation 0.5s forwards;
			}
		}
	}
}

.roomContents {
	padding-bottom: 80px;

	@media screen and (min-width: 847px) {
		padding-top: 56px;
		box-sizing: border-box;
	}

	@media screen and (max-width: 846px) {
		padding-bottom: 40px;
	}

	.postList {
		@media screen and (min-width: 847px) {
			margin: 0 auto;
			padding-left: 15px;
			padding-right: 15px;
			max-width: 726px;
			box-sizing: border-box;
		}

	}

	.postNone {
		padding: 108px 24px 48px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	// 検索結果の画面
	&.-search {
		.conditions {
			font-size: 18px;
			font-weight: bold;
			margin: 24px 0;
		}

		.searchTabs {
			box-sizing: border-box;

			@include bkp(pc) {
				margin-bottom: 16px;
			}

			ul {
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				border-bottom: solid 1px #dddddd;
				width: 726px;
				margin: 0 auto;

				@include bkp(sp) {
					width: 100%;
				}

				>li {
					box-sizing: border-box;
					cursor: pointer;
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					width: 26%;
					max-width: 230px;
					height: 40px;
					font-size: 14px;
					font-weight: bold;
					line-height: 1.4;

					@include bkp(sp) {
						width: calc(100% / 3);
					}

					@media screen and (max-width:374px) {
						font-size: 13px;
					}

					>span {
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 8px;
						height: 100%;
					}

					&.current {
						color: #45B5F5;
						border-bottom: 3px solid #45B5F5;
					}
				}
			}
		}

		.postList {

			// padding: 16px 0;
			.comment {
				border-bottom: 1px solid #ddd;
				margin-top: 16px;

				&:first-child {
					margin-top: 0;
				}

				@include bkp(sp) {
					padding: 0 16px;
				}
			}

			&.-comment {
				.comment {
					&:first-child {
						@include bkp(pc) {
							padding-top: 16px;
						}

						@include bkp(sp) {
							padding-top: 24px;
						}
					}
				}
			}
		}

		.noSearchResults {
			margin-top: 32px;

			@include bkp(sp) {
				margin-top: 24px;
				padding: 0 16px;
			}

			p {
				color: #666666;
				font-size: 16px;
				text-align: center;

				@include bkp(sp) {
					font-size: 14px;
				}
			}
		}
	}
}

.roomSettings {
	padding: 32px 40px;

	@media screen and (max-width: 846px) {
		padding: 24px 16px;
	}

	.heading {
		font-weight: bold;
		font-size: 22px;
		margin-bottom: 10px;
	}

	.description {
		font-size: 15px;
		margin-bottom: 16px;
	}

	.roomSettings__item {
		margin-bottom: 56px;
	}
}

.roomSelect {
	padding: 64px 40px 32px;

	@media screen and (max-width: 846px) {
		padding: 32px 24px;
	}

	.description {
		text-align: center;
		font-size: 15px;
		margin-bottom: 32px;
	}

	.roomSettings__item {
		margin-bottom: 56px;
	}
}


.formList {
	.formList__item {
		&+.formList__item {
			margin-top: 56px;
		}
	}

	.selectNum {
		font-size: 14px;

		.MuiSelect-selectMenu {
			width: 56px;
			height: 24px;
			line-height: 24px;
			padding: 8px 8px;
			font-size: 16px;
		}
	}

	.separator {
		font-size: 16px;
		margin: 0 12px;
	}
}

.form {
	&.-row {
		display: flex;

		@include bkp(sp) {
			flex-direction: column;
		}
	}

	.form__item {
		&.-left {
			@include bkp(pc) {
				flex-grow: 1;
				flex-basis: 192px;
				flex-shrink: 0;
			}

			&+.-right {
				@include bkp(pc) {
					margin-left: 32px;
				}
			}
		}

		&.-right {
			@include bkp(pc) {
				flex-grow: 2;
				flex-basis: 656px;
			}
		}
	}

	.formHeading {
		display: block;
		font-size: 14px;
		margin-bottom: 16px;
		color: #333;
		font-weight: bold;
		line-height: 1;

		.required {
			margin-left: 8px;
			font-size: 12px;
			color: white;
			background: #ff6d6d;
			padding: 1px 6px;
			border-radius: 3px;
			height: 18px;
			display: inline-flex;
			align-items: center;
			flex-shrink: 0;
		}
	}

	.formDescription {
		font-size: 15px;
		margin-bottom: 24px;
	}

	.paragraph {
		margin-bottom: 16px;
	}

	.inputWrap {
		position: relative;
	}

	.MuiOutlinedInput-root {
		border-radius: 8px;
	}

	.radioBtn {
		&+.radioBtn {
			margin-top: 16px;
		}

		.radioBtn__inner {
			display: flex;
			align-items: center;
			max-width: 256px;
			flex-wrap: wrap;

			input[type="radio"] {
				margin-right: 16px;

				&+label {
					opacity: .3;

					input {
						pointer-events: none;
					}

					&+.inputNotice {
						opacity: .3;
					}
				}

				&:checked {
					&+label {
						opacity: 1;

						input {
							pointer-events: auto;
						}

						&+.inputNotice {
							opacity: 1;
						}
					}
				}
			}

			.inputNotice {
				margin-left: 32px;
			}
		}
	}

	.inputControl {
		font-size: 16px;
		display: block;
		width: 100%;
		min-height: 50px;
		margin: 0;
		color: #333;
		outline: none;
		box-sizing: border-box;
		box-shadow: none;
		border-radius: 8px;
		box-shadow: none;
		background-color: transparent;
		-webkit-appearance: none;

		&.-small {
			display: flex;
			align-items: center;

			input {
				width: 144px;
				min-height: 56px;
				margin-right: 8px;
				padding: 0px 8px;
			}
		}

		input {
			font-size: 16px;
			display: flex;
			align-items: center;
			width: 100%;
			min-height: 64px;
			padding: 13px 6px;
			margin: 0;
			background-color: #fff;
			border: 1px solid #CBCBCB;
			outline: none;
			border-radius: 8px;
			box-sizing: border-box;
			word-break: break-all;
			box-shadow: none;
			-webkit-appearance: none;
		}

		.textarea {
			font-size: 16px;
			display: block;
			width: 100%;
			min-height: 64px;
			padding: 13px 6px;
			margin: 0;
			background-color: #fff;
			border: 1px solid #CBCBCB;
			outline: none;
			border-radius: 3px;
			box-sizing: border-box;
			word-break: break-all;
			box-shadow: none;
			-webkit-appearance: none;

			&.-commentDetail {
				min-height: 56px;

				.MuiInputBase-input {
					line-height: 1.3;
					font-size: 16px;
					display: block;
					width: 100%;
					min-height: 56px;
					margin: 0;
					background-color: #fff;
					outline: none;
					box-sizing: border-box;
					word-break: break-all;
					box-shadow: none;
					-webkit-appearance: none;
				}

				.MuiInputBase-multiline {
					padding: 0;
				}
			}

			.MuiInput-underline:before,
			.MuiInput-underline:after {
				display: none;
			}
		}

		.MuiInputBase-root {
			display: block;
		}

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid #CBCBCB;
		}

		&.-post {
			min-height: 64px;

			input {
				font-size: 20px;
				font-weight: bold;
				border: 0;
				padding: 8px 0;
				min-height: auto;
				border-bottom: 1px solid #ddd;

				&::placeholder {
					color: #b2b2b2;
				}
			}

			textarea {
				font-size: 16px;
				width: 100%;
				min-height: 200px;
				padding: 8px 0;
				border: 0;
				border-bottom: 1px solid #ddd;
				appearance: none;
				resize: none;
				outline: none;
				background: transparent;

				&::placeholder {
					color: #b2b2b2;
				}
			}
		}

		&.-postTitle {
			min-height: 32px;
			line-height: 32px;
			padding-bottom: 16px;

			input {
				font-size: 20px;
				font-weight: bold;
				border: 0;
				padding: 8px 0;
				min-height: auto;
				border-bottom: 1px solid #ddd;

				&::placeholder {
					color: #b2b2b2;
				}
			}

			textarea {
				font-size: 20px;
				font-weight: bold;
				width: 100%;
				height: 32px;
				min-height: 32px;
				padding: 8px 0;
				border: 0;
				border-bottom: 1px solid #ddd;
				appearance: none;
				resize: none;
				outline: none;
				background: transparent;

				&::placeholder {
					color: #b2b2b2;
				}
			}
		}
	}

	.inputNotice {
		margin: 8px 0 8px;
		font-size: 12px;
	}

	.counter {
		position: absolute;
		bottom: 5px;
		right: 8px;
		color: #707070;
		font-size: 13px;

		&.-post {
			display: flex;
			bottom: 0;
			right: 0;
		}

		&.-static {
			position: static;
			display: flex;
			justify-content: flex-end;
		}

		.counter__item {
			&+.counter__item {
				margin-left: 16px;
			}
		}

		.alertText {
			margin-right: 16px;
		}
	}

	.fileUpload {
		width: 332px;

		.fileUploadField {
			width: 332px;
			height: 187px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #707070;
			background-color: #F0F0F0;
			cursor: pointer;

			input[type="file"] {
				display: none;
			}

			svg {
				fill: #707070;
				margin-right: 8px;
			}
		}
	}

	.noticeText {
		margin: 0;

		.noticeText__item {
			letter-spacing: -0.05em;
			font-size: 11px;
			color: #888;
			line-height: 1.3;

			&.-space {
				margin-left: 6px;
			}

			&+.noticeText__item {
				margin-top: 8px;
			}
		}
	}
}
#simple-popper {
	z-index: 1;
}
#simple-popper,
#simple-popper-sp {
	background-color: white;
	border: 1px solid #ccc;
	padding: 16px 24px;
	border-radius: 6px;

	.roomChangeLink {
		text-align: right;
		margin-top: 8px;

		a {
			color: #333;
			text-decoration: underline;
		}
	}
}

// html {
// 	overflow-y: scroll;
// }

.react-player__preview img {
	width: 25%;
	height: 25%;
}

// MUIスイッチのリスト（ルームごとの投稿制限で使用）
.formDl {
	margin-top: 24px;

	>dt {
		margin-bottom: 8px;
	}

	>dd {
		margin-left: 10%;

		>dl {
			display: flex;
			justify-content: space-between;

			>dd {
				margin-left: 8px;
			}

			&+dl {
				margin-top: 18px;
			}
		}
	}
}
