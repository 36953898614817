.contents-body{
    padding: 25px 40px 40px;
    @media #{$sp} {
        padding:20px;
    }
    &.-register {
        display: flex;
        padding-top: 40px;
        @media #{$sp} {
            flex-direction: column;
            padding-top: 24px;
        }

        .contents-body__item {
            &.-left {
                @media #{$pc} {
                    width: 328px;
                }
            }
            &.-center {
                display: flex;
                align-items: center;
                @media #{$pc} {
                    margin: 0 45px;
                }
            }
            &.-right {
                @media #{$pc} {
                    width: 328px;
                }
                .notice {
                    text-align: center;
                }
            }
        }
    }
    .separate {
        position: relative;
        @media #{$pc} {
            height: 100%;
            &:before,
            &:after {
                content: "";
                width: 1px;
                height: 46%;
                background: #999;
                display: block;
                margin: 0 auto;
            }
            &:before {
                margin-bottom: 8px;
            }
            &:after {
                margin-top: 8px;
            }
        }
        @media #{$sp} {
            width: 100%;
            text-align: center;
            margin: 32px 0;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 40%;
                height: 1px;
                background: #999;
                display: block;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
    }
}

.contents-header{
    text-align: center;
    padding: 40px 0 0 0;
    @media #{$sp} {
        padding: 16px 0 0 0;
    }
    h1 {
        font-size: 26px;
        @include bold;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$sp} {
            font-size: 22px;
        }
        &.switch-right-center {
            justify-content: left;
            @media #{$sp} {
                justify-content: center;
            }
        }
    }
    h2 {
        font-size: 22px;
        @include bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .description{
        margin-top: 10px;
        font-size: 14px;
        color:$text-base-color;
        padding: 20px 40px;
        @media #{$sp} {
            padding:20px;
        }
    }
    .icon{
        margin: 20px auto 0 auto;
        width: 100px;
        height: 100px;
        img{
            display: block;
            box-sizing: border-box;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            border:2px solid #fff;
        }
    }
    .helpIcon {
        fill: #666;
        margin-left: 5px;
    }
    &.add-border-bottom {
        border-bottom: 2px solid #e2e2e2;
        @media #{$sp} {
            border: none;
        }
    }
}

.box {
    padding: 20px;
    &.box-gray {
        padding: 30px 20px;
        background: #eee;
        margin: 20px;
        border-radius: 8px;
    }
    .box-item {
        & + .box-item {
            margin-top: 20px
        }
    }
    .box-title {
        font-size: 16px;
        @include bold;
    }
    .box-description {
        margin-top: 10px;
    }
    &.box-center,
    .box-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.box-round{
    padding: 20px;
    border-radius: 6px;
    border: 1px solid $border-color;
}

.tabmenu{
    margin-top: 20px;
    ul{
        display: flex;
        li{
            width: 50%;
            text-align: center;
            a{
                display: block;
                padding: 10px;
                color: $text-strong-color;
                border-bottom: 1px solid $border-color;
                &.active{
                    color:$link-color;
                    border-bottom: 1px solid $link-color;
                }
            }
        }
    }
}
.MuiTabs-root {
    .MuiTab-root {
        font-size: 14px;
        font-weight: bold;
        padding: 6px 8px;
    }
    .sp-MuiTabs > & {
        display: none;
        @media only screen and (max-width: 640px) {
            display: flex;
        }
    }
}
.social-btns-wrapper {
    margin: 0;
    &.-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        .social-btns li a {
            height: 36px;
            width: 36px;
            border-radius: 36px;
            &.twitter {
                background: #1D9BF0;
            }
            &.line {
                margin: 0 16px;
                background: #4CC764;
            }
            &.clipboard {
                background: #b2b2b2;
            }
        }
    }
}
.social-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
        width: 26px;
        height: 26px;
        & + li {
            margin-left: 8px;
        }
        button {
            margin: 0;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            width: 26px;
            border-radius: 26px;
            &.twitter {
                background: #1D9BF0;
                svg{
                    height: 16px;
                    margin-top: 2px;
                }
            }
            &.line {
                background: #4CC764;
                svg{
                    height: 18px;
                    width: 20px;
                }
            }
            &.clipboard {
                background: #333;
                .material-icons{
                    color: #fff;
                    display: flex;
                    align-items: center;
                }
                svg{
                    height: 18px;
                }
            }
        }
    }
    .-room & {
        li {
            a {
                background: transparent;
                svg{
                    fill: #b2b2b2;
                    margin-top: 0;
                    height: 20px;
                }
                &.twitter {
                    svg{
                        width: 20px !important;
                        height: 20px;
                    }
                }
                &.line {
                    svg{
                        width: 22px !important;
                        height: 22px;
                    }
                }
                &.clipboard {
                    svg{
                        width: 24px !important;
                        height: 24px;
                    }
                }
            }
        }
    }
    &.-large {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            width: 48px;
            height: 48px;
            & + li {
                margin-left: 24px;
            }
            a {
                height: 48px;
                width: 48px;
                border-radius: 48px;
                &.twitter {
                    svg{
                        width: 24px;
                        height: 24px;
                    }
                }
                &.line {
                    svg{
                        width: 35px;
                        height: 35px;
                        margin-top: 1px;
                        margin-right: 1px;
                    }
                }
                &.clipboard {
                    text-align: center;
                    color: #ffff;
                    .material-icons,
                    .MuiSvgIcon-root {
                        width: 30px !important;
                        height: 30px;
                    }
                }
            }
        }
    }
}

.door-register{
    padding: 20px;
}

.alert{
    text-align: center;
    color:$warning-text-color;
    padding: 20px;
    background: $warning-bg-color;
    border-radius: 0;
    &.-info {
        color: #31708f;
        background-color: #d9edf7;
    }
}

.user-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .user{
        display: flex;
        align-items: center;
        .icon{
            padding-right: 10px;
            .MuiAvatar-root {
                width: 56px;
                height: 56px;
            }
            img{
                display: block;
                box-sizing: border-box;
                width: 56px;
                height: 56px;
                border-radius: 100%;
                object-fit: cover;
            }
        }
        .username{
            margin-bottom: 3px;
            font-weight: 500;
            font-size: 16px;
            color: $text-base-color;
            .date {
                font-size: 12px;
                color: #888;
                font-weight: 400;
                margin-top: 5px;
            }
        }
        .user__inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .userID {
            margin-right: 8px;
            font-size: 13px;
            color: #888888;
        }
        .date {
            font-size: 13px;
            color: #888888;
        }
    }
    .action{
        cursor: pointer;
        .material-icons{
            color: $text-notice-color;
        }
    }
}

.userIdWrap {
    display: flex;
    margin-top: 8px;
    @include bkp(pc){
        align-items: center;
        justify-content: space-between;
    }
    @include bkp(sp){
        flex-direction: column;
        align-items: flex-end;
    }
    .userId {
        font-size: 15px;
        font-weight: bold;
        @include bkp(sp){
            width: 100%;
            margin-bottom: 8px;
        }
    }
    .copyBtn {
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        background: #fff;
        color: #333;
        border: 1px solid #888;
        width: 72px;
        height: 24px;
        line-height: 24px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        flex-shrink: 0;
        &:hover {
            opacity: .8;
        }
    }
}

.posts{
    position: relative;
    &.-overlay {
        * {
            pointer-events: none;
        }
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: #00000057;
            display: block;
            top: 0;
            left: 0;
            z-index: 1000;
        }
    }
    >li{
        border-bottom:1px solid $border-color;
        padding: 20px;
        @media only screen and (max-width: 640px) {
            padding: 15px;
        }
        &:last-child{
            border-bottom:none;
        }
        .user-header{
            margin-bottom: 10px;
        }
        .text{
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.6;
            color: $text-base-color;
            word-break: break-word;
            a {
                color: $text-base-color;
            }
        }
        .media{
            margin-bottom: 20px;
            li{
                position: relative;
                width: 100%;
                padding-top: 56.25%;
                img{
                    border: 1px solid #c4cfd6;
                    border-radius: 4px;
                    overflow: hidden;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &.-detail {
            li{
                padding-top: 0;
                img{
                    position: static;
                    width: 100%;
                    height: auto;
                }
            }
            }
        }
        .item-body {
            .date {
                text-align: right;
                font-size: 12px;
                color: #888;
            }
        }
        .item-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.-postList {
                color: #888;
                justify-content: flex-end;
            }
            a {
                display: flex;
                align-items: center;
            }
            .comment{
                font-size: 14px;
                color: $text-notice-color;
                a {
                    color: $text-base-color;
                }
                svg {
                    margin-right: 5px;
                }
                .comment-btn {
                    // background: $main-color;
                    // color: #fff;
                    // padding: 3px 8px;
                    // border-radius: 4px;
                    cursor: pointer;
                    svg {
                        color: #fff;
                        font-size: 18px !important;
                        width: 18px !important;
                        margin-right: 5px;
                    }
                }
            }
            .date{
                text-align: right;
                font-size: 12px;
                color: $text-notice-color;
            }
            .FavoriteIconWrap {
                height: 20px;
                color: #888;
                .MuiSvgIcon-root {
                    cursor: pointer;
                    color: #b2b2b2;
                    vertical-align: -6px;
                    margin-right: 3px;
                }
                .FavoriteIcon {
                    color: #dc3053;
                }
                .FavoriteCount {
                    cursor: pointer;
                }
                a {
                    color: $text-base-color;
                }
            }
            .CommentIconWrap {
                margin-left: 24px;
                img {
                    cursor: pointer;
                    color: #888;
                    vertical-align: -5px;
                }
            }
        }
    }
    &.-commentDetails {
        background: #fff;
        border-radius: 6px;
        margin-bottom: 32px;
        @media #{$sp} {
            border-radius: 0;
        }
        > li {
            padding: 32px 32px 12px;
            @media #{$sp} {
                padding: 24px 14px 12px;
            }
        }
    }
    .posts-footer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 12px 0 0;
        margin-top: 12px;
        border-top: 1px solid #d7dcdf;
        .favoriteBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            font-size: 15px;
            font-weight: bold;
            color: #333;
            cursor: pointer;
            .MuiSvgIcon-root {
                margin-right: 5px;
            }
        }
        .commentBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            font-size: 15px;
            font-weight: bold;
            color: #333;
            cursor: pointer;
            img {
                margin-right: 5px;
                vertical-align: -4px;
            }
        }
        .partition {
            width: 1px;
            height: 33px;
            background: #d7dcdf;
            display: block;
        }
    }
    .posts__inner {
        &.-commentList {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            @media #{$sp} {
                flex-direction: column;
                align-items: baseline;
                padding: 0;
                .posts {
                    margin-top: 12px;
                    align-self: flex-end;
                }
            }
            .posts .posts-footer {
                padding: 0;
                margin-top: 0;
                border-top: 0px solid #d7dcdf;
            }
        }
    }
}

.reply {
    display: block;
    padding: 12px 0 0;
    margin-top: 16px;
    border-top: 1px solid #d7dcdf;
    text-align: left;
    @media #{$sp} {
        text-align: right;
    }
}

.favoriteUserList {
    li {
        border-bottom: 1px solid #d7dcdf;
    }
    .favoriteUserList__item {
        padding: 12px;
        display: flex;
        align-items: center;
    }
    .favoriteUserIcon {
        margin-right: 10px;
        img {
            display: block;
            box-sizing: border-box;
            width: 48px;
            height: 48px;
            border-radius: 100%;
            object-fit: cover;
        }
    }
    .favoriteUserBody {
        font-weight: bold;
        font-weight: 600;
        font-size: 13px;
        color: #333;
    }
}

.comments{
    li{
        position: relative;
        padding: 0;
        & + li {
            border-top:1px solid $border-color;
        }
        &:first-child{
            border-top:1px solid $border-color;
        }
        .reply-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ccc;
        }
        .user-header{
            margin-bottom: 10px;
        }
        .text{
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 1.9;
            color: $text-base-color;
            word-break: break-word;
        }
        .item-footer{
            .date{
                text-align: right;
                font-size: 12px;
                color: $text-notice-color;
            }
        }
    }
    &.-commentDetails {
        li {
            margin-bottom: 32px;
            & + li {
                border-top: 0;
            }
            &:first-child{
                border-top: 0;
            }
            @media #{$sp} {
                padding: 0 14px;
            }
            .inner {
                display: flex;
                &:first-child {
                    .commentsBody {
                        border-radius: 6px;
                        padding: 16px 32px 20px;
                        @media #{$sp} {
                            padding: 16px 16px 20px;
                        }
                    }
                }
                &:last-child {
                    .commentsBody {
                        padding: 16px 32px 16px;
                        @media #{$sp} {
                            padding: 16px 16px 16px;
                        }
                    }
                }
                .commentsBody {
                    border-radius: 6px 6px 0 0;
                    @media #{$sp} {
                        padding: 16px 16px 16px;
                    }
                    .posts-footer {
                        .favoriteBtn {
                            @media #{$sp} {
                                font-size: 13px;
                            }
                        }
                        .commentBtn {
                            @media #{$sp} {
                                font-size: 13px;
                            }
                        }
                        .partition {
                        }
                    }
                }
                & + .inner {
                    margin-top: -4px;
                    .commentsBody {
                        border-radius: 0;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 100%;
                            height: 1px;
                            border-top: 2px solid #E2E2E2;
                        }
                    }
                    &:last-child {
                        .commentsBody {
                            border-radius: 0 0 6px 6px;
                            padding: 16px 32px 16px;
                            @media #{$sp} {
                                padding: 20px 16px 16px;
                            }
                        }
                    }
                }
                &.-comment {
                    margin-top: -4px;
                    .commentsBody {
                        &:before {
                            border-top: 1px dashed #E2E2E2;
                        }
                    }
                }
            }
        }
        .commentsBody {
            position: relative;
            background: #fff;
            border-radius: 6px;
            padding: 32px 32px 16px;
            width: 100%;
            &:after {
                content: "";
                position: absolute;
                top: 34px;
                left: -12px;
                width: 0px;
                height: 0px;
                border: 6px solid transparent;
                border-right: 6px solid #fff;
            }
        }
        .user-header .user {
            display: block;
        }
        .icon{
            padding-top: 16px;
            padding-right: 14px;
            .MuiAvatar-root {
                width: 48px;
                height: 48px;
            }
            img{
                display: block;
                box-sizing: border-box;
                width: 48px;
                height: 48px;
                border-radius: 100%;
            }
        }
    }
}

.post-comment{
    padding: 20px;
}


.notifications{
    li{
        & + li {
            border-top: 1px solid #e2e2e2;
        }
        a {
            padding: 20px;
            @media #{$sp} {
                padding: 16px 8px;
                display: block;
            }
            .salon-item {
                display: flex;
                justify-content: space-between;
                gap: 16px;
                font-size: 12px;
                color: #888;
                margin-top: 16px;
                .salon-name {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
                .date {
                    flex-shrink: 0;
                }
            }
        }
        .user{
            .icon{
                position: relative;
                display: block;
                box-sizing: border-box;
                min-width: 40px;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                border: 0 solid #fff;
                margin-right: 12px;
            }
        }
        .item-body{
            flex-grow: 2;
            .title{
                @include bold;
                font-size: 14px;
                margin-bottom: 4px;
                color: $text-strong-color;
            }
            .sub{
                @include bold;
                font-size: 14px;
                margin-bottom: 4px;
                color: $text-notice-color;
            }
            .comment{
                display: block;
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 1.4;
                color: $text-base-color;
            }
            .content {
                font-weight: 500;
                font-size: 12px;
            }
            .date{
                text-align: right;
                font-size: 12px;
                color: $text-notice-color;
            }
            .function{
                display: flex;
                align-items: center;
                justify-content: space-between;
                & + .function {
                    margin-top: 16px;
                }
                @media #{$sp} {
                    display: block;
                }
            }
            .status{
                display: flex;
                align-items: center;
                @media #{$sp} {
                    margin-bottom: 10px;
                }
                .period{
                    margin-right: 10px;
                    color:$text-notice-color;
                    @media #{$sp} {
                        letter-spacing: -.05em;
                        font-size: 12px;
                    }
                }
                .price{
                    color:$text-strong-color;
                    font-size: 12px;
                    strong{
                        font-size: 16px;
                        color: $main-color;
                    }
                }
            }
            .action {
                @media #{$sp} {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .notification_omitted {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }
    }
}

.member{
    >li{
        display: flex;
        align-items: center;
        border-bottom:1px solid $border-color;
        padding: 20px;
        cursor: pointer;
        &:last-child{
            border-bottom:none;
        }
        .user{
            .icon{
                padding-right: 10px;
                img{
                    display: block;
                    box-sizing: border-box;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border:2px solid #fff;
                }
            }
        }
        .item-body{
            .title{
                @include bold;
                font-size: 14px;
                margin-bottom: 4px;
                color: $text-strong-color;
            }
        }
    }
}

.empty{
    padding: 60px 20px;
    font-size: 14px;
    color: $text-notice-color;
    text-align: center;
}

.box-sales{
    background: $main-color;
    border-radius: 6px;
    padding: 20px;
    .title{
        color: #fff;
        @include bold;
        font-size: 14px;
    }
    .price{
        color: #fff;
        @include bold;
        font-size: 32px;
        @media only screen and (max-width: 640px) {
            padding-right: 88px;
            text-align: right;
            letter-spacing: -0.05em;
        }
    }
    .function{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -5px;
        margin: auto;
        a{
            display: block;
            width: 80px;
            padding: 2px 0;
            border-radius: 4px;
            color: #fff;
            border: 1px solid #fff;
            @include bold;
            font-size: 14px;
        }
    }
    .box-sales__inner {
        text-align: center;
        position: relative;
    }
}

.box-price{
    .title{
        @include bold;
        font-size: 14px;
        color: $text-strong-color;
        margin-bottom: 10px;
    }
    .function{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -5px;
        margin: auto;
        li{
            padding: 0 4px;
        }
        a{
            display: block;
            width: 80px;
            padding: 2px 0;
            border-radius: 4px;
            color: $main-color;
            border: 1px solid $main-color;
            @include bold;
            font-size: 14px;
        }
    }
    .box-price__inner {
        text-align: center;
        position: relative;
    }
}

.monthly-price{
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.05em;
    @media only screen and (max-width: 640px) {
        &.-dashboard {
            display: block;
            padding-right: 88px;
            text-align: right;
        }
    }
    &.-owner {
        strong {
            margin: 0 8px;
        }
    }
    .label{
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
        background: #11b5f5;
        color: #fff;
        padding: 2px 8px 1px;
        border-radius: 2px;
    }
    strong{
        @include bold;
        font-size: 32px;
    }
    .tax {
        position: relative;
        bottom: -4px;
    }
}

.table-sales{
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    th,td{
        padding: 16px;
        border: 0;
        border-bottom: 1px solid $border-color;
    }
    td{
        color: $text-base-color;
    }
    th{
        text-align: right;
        color: $text-strong-color;
    }
    tr:last-child{
        th,td{
            border: none;
        }
    }
}

.CVC {
    margin-top: 10px;
    display: flex;
    img {
        margin-right: 10px;
    }
}

.prosessing {
    text-align: center;
    background: #eaeaea;
    padding: 8px;
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 16px;
    span {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: normal;
    }
}

video {
    background-color: black;
}

.featureHeader {
    .featureImage {
        img {
            max-width: 100%;
            vertical-align: bottom;
        }
    }
    .featureDescription {
        margin: 52px 0 16px;
        text-align: center;
        font-size: 16px;
        @media only screen and (max-width: 640px) {
            font-size: 14px;
            margin: 24px 0 0;
            padding: 0 16px;
        }
    }
}

.annotation-background {
    background: $light-color;
}

.annotation-body {
    padding: 16px 8px;
    text-align: left;
    font-size: 12px;
    display:inline-block;
}

.headsUpBox {
    border: 1px solid #ff6d6d;
    border-radius: 8px;
    padding: 24px;
    @media screen and (max-width: 846px) {
        padding: 16px;
    }
    &__text {
        color: #ff6d6d;
    }
    // PCのみmargin-left
    &.ml {
        @media screen and (min-width: 847px) {
            margin-left: 38px;
        }
    }
}

.MuiPaper-root.MuiDrawer-paper {
    overflow: auto;
    .modal-drawer {
        overflow: auto;
    }
}
.staff_doors_wrapper {
    .MuiDialog-paper {
        margin: 0 16px;
        @media #{$sp} {
            margin: 0;
        }
    }
}
.staff_doors {
    position: relative;
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 4px;
    padding: 16px 0 24px;
    cursor: default;
    @media #{$sp} {
        width: 92vw;
        min-width: auto;
    }
    .staff_doors_title {
        font-size: 18px;
        margin-bottom: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        @media #{$sp} {
            font-size: 16px;
        }
    }
    .close {
        position: absolute;
        top: 8px;
        right: 8px;
        color: #2D2D2D;
        font-size: 20px !important;
        width: 20px !important;
        cursor: pointer;
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
        li {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 12px;
            .image {
                width: 100px;
                height: 60px;
                overflow: hidden;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .title {
                flex-grow: 1;
            }
            &.selected {
                .image {
                    border: 2px solid #45B5F5;
                }
                .title {
                    color: #45B5F5;
                }
            }
        }
    }
}
