.error-message {
  color: gray;
  font-weight: bold;
  .title {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: large;
    text-align: center;
  }
  .message {
    display: table;
    margin: 0 auto;
    .link {
      margin-top: 10px;
    }
  }
}
