.planList {
  .planList__item {
    margin-bottom: 24px;

    .MuiRadio-root {
      @media screen and (min-width: 847px) {
        display: block;
      }

      @media screen and (max-width: 846px) {
        display: none;
      }
    }

    .undeletableMessage {
      padding-left: 45px;
      margin-top: 10px;
      color: #ff6d6d;
    }
  }

  .planList__nav {
    margin-bottom: 12px;
    text-align: right;
  }

  .planList__sortNav {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .description {
      flex: 2;
    }

    .btns {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0px 32px;

      >* {
        flex-shrink: 0;
      }

      .btn {
        width: 160px;
      }

      @media screen and (max-width: 846px) {
        display: none;
      }
    }
  }

  .planList_sortBtn {
    font-size: 16px;
    color: grey;
  }
}

.planWrap {
  display: flex;
  align-items: center;

  &.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 0;

    .MuiFormControlLabel-label {
      width: 100%;
    }
  }

  .Mui-checked+.MuiFormControlLabel-label {
    .plan {
      border: 1px solid transparent;

      &::after {
        content: '';
        border: 2px solid #45b5f5;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        box-sizing: border-box;
      }

      // @media screen and (max-width: 480px) {
      // 	border: 0px solid transparent;
      // 	background: #45b5f5;
      // 	color: #fff;
      // 	&::after {
      // 		display: none;
      // 	}
      // }
    }
  }

  .undeletable+.MuiFormControlLabel-label {
    .plan {
      border: 1px solid transparent;

      &::after {
        content: '';
        border: 2px solid #ff6d6d;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        box-sizing: border-box;
      }

      // @media screen and (max-width: 480px) {
      // 	border: 0px solid transparent;
      // 	background: #45b5f5;
      // 	color: #fff;
      // 	&::after {
      // 		display: none;
      // 	}
      // }
    }
  }

  // .MuiRadio-root {
  // 	@media screen and (max-width: 480px) {
  // 		display: none;
  // 	}
  // }
}

.planCheckbox {
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: #45b5f5;
  }

  .MuiSvgIcon-root {
    font-size: 24px !important;
    width: 24px !important;
  }
}

.plan {
  position: relative;
  max-width: 880px;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 24px;
  flex-grow: 1;

  &.-planSelect {
    padding: 0 0 24px;
    overflow: hidden;

    @include bkp(sp) {
      padding: 0 0 16px;
    }

    .planHeading {
      margin: 24px 24px 0;

      @include bkp(sp) {
        margin: 16px 16px 0;
      }

      .planHeading__item {
        &.-top {
          @media screen and (max-width: 846px) {
            justify-content: normal;
          }

          .MuiRadio-root {
            @media screen and (min-width: 847px) {
              display: none;
            }

            @media screen and (max-width: 846px) {
              display: flex;
              padding: 0;
              margin-right: 8px;

              .MuiIconButton-label {
                input {
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }

    .planBody {
      padding: 0 24px;

      @include bkp(sp) {
        padding: 0 16px;
      }

      .planBody__item.-right {
        @include bkp(sp) {
          flex-shrink: 1;
        }
      }
    }
  }

  &+.plan {
    margin-top: 16px;
  }

  .planState {
    display: flex;
    align-items: center;
    font-weight: bold;
    width: max-content;

    @include bkp(pc) {
      padding: 4px 16px;
    }

    @include bkp(sp) {
      padding: 4px 8px;
      font-size: 12px;
    }

    &.-suspended {
      background: #ffffff;
      color: grey;
      border: 1px solid;
      box-sizing: border-box;
      height: 22px;

      @include bkp(sp) {
        height: 20px;
      }
    }

    &.-open {
      background: #ffffff;
      color: #45b5f5;
      border: 1px solid;
      box-sizing: border-box;
      height: 22px;

      @include bkp(sp) {
        height: 20px;
      }
    }

    &.-invisible {
      background: #333;
      color: #ffffff;
    }

    &.-visible {
      background: #45b5f5;
      color: #fff;
    }

    &.-secret {
      background: #fff;
      color: black;
      border: 1px solid;
      box-sizing: border-box;
      height: 22px;

      @include bkp(sp) {
        height: 20px;
      }
    }
  }

  .planHeading {
    position: relative;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 12px;

    &.-planList {
      flex-direction: column;
      align-items: normal;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px dashed #ccc;
    }

    &.-entryPlan {
      align-items: normal;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px dashed #ccc;
    }

    a {
      font-weight: bold;
      flex-shrink: 0;
      margin-left: 16px;
      cursor: pointer;
    }

    .btn {
      margin-left: 16px;

      @include bkp(sp) {
        display: none;
      }
    }

    h3 {
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
    }

    &+.planBody {
      margin-top: 10px;
    }

    &+.price {
      margin-bottom: 8px;
    }

    .planHeading__item {
      &.-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.-center {
        @include bkp(pc) {
          display: none;
        }

        @include bkp(sp) {
          display: block;
        }
      }

      &+.planHeading__item {
        margin-top: 8px;
      }
    }

    .stateWrapper {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 6px;
      margin-bottom: 12px;
    }
  }

  .planBody {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;

    @include bkp(sp) {
      flex-wrap: wrap-reverse;
    }

    .planBody__item {
      &.-left {
        flex-grow: 2;
        max-width: 496px;

        @include bkp(sp) {
          max-width: none;
        }
      }

      &.-right {
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 315px;
        margin-left: 24px;

        @include bkp(sp) {
          display: none;
          // flex-shrink: 1;
          // max-width: none;
          // margin-left: 0;
        }
      }
    }

    .planImage {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 315px;
      max-height: 180px;
      font-size: 14px;
      color: #777;
      font-weight: bold;
      margin: 0 auto;
      overflow: hidden;

      @include bkp(sp) {
        margin: 8px auto 24px;
      }

      img {
        // max-width: 100%;
        // height: auto;
        object-fit: cover;
        max-width: 315px;
        max-height: 180px;
        width: 315px;
        height: 180px;
      }
    }

    .planText {
      white-space: pre-wrap;

      .planTextHeading {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .planText__item {
        &+.planText__item {
          margin-top: 16px;
        }
      }
    }
  }

  .planFooter {
    @include bkp(pc) {
      display: none;
    }
  }

  .planImage {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 315px;
    max-height: 180px;
    font-size: 14px;
    color: #777;
    font-weight: bold;
    margin: 0 auto;
    overflow: hidden;

    @include bkp(sp) {
      margin: 8px auto;
    }

    img {
      // max-width: 100%;
      // height: auto;
      object-fit: cover;
      max-width: 315px;
      max-height: 180px;
      width: 315px;
      height: 180px;
    }
  }

  .planText {
    .planTextHeading {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .planText__item {
      &+.planText__item {
        margin-top: 16px;
      }

      dl {
        display: flex;
        flex-direction: column;

        dt {
          font-weight: bold;
        }

        dd {
          margin-top: 4px;
        }
      }

      // プランdescriptionの折りたたみ
      .textTruncate-container {
        position: relative;
        display: flex;
        flex-direction: column;
      }

      .textTruncate-text {
        position: relative;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 15;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .textTruncate-text.-truncated:before {
        content: "";
        display: block;
        height: 100px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
      }

      .text.expanded {
        -webkit-line-clamp: unset;
      }

      .textTruncate-readMore {
        background: transparent;
        color: #45b5f5;
        border: none;
        padding: 10px 20px;
        margin-top: 8px;
        cursor: pointer;
        font-size: 14px;
        font-family: "Noto Sans JP", sans-serif;
      }
    }
  }

  .planStatus {
    margin-bottom: 16px;

    .planStatus__item {
      display: flex;

      &+.planStatus__item {
        margin-top: 16px;
      }

      dl {
        display: flex;
        flex-direction: column;

        dt {
          font-weight: bold;
        }

        dd {
          margin-top: 4px;
        }
      }
    }
  }

  .price {
    flex-shrink: 0;
    font-size: 15px;

    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .unique {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    background: #ff934d;
    color: #fff;
    font-weight: bold;
    font-size: 14px;

    &.-secondary {
      background: #45b5f5;
    }

    &.-disable {
      background: #333;
    }
  }

  .uniqueBatch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    background: #28D6A7;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 30px;
    padding: 0 16px;
    margin-top: 4px;
    margin-right: 8px;

    @media screen and (max-width: 389px) {
      font-size: 12px;
      padding: 0 8px;
    }

    @media screen and (max-width: 365px) {
      font-size: 10px;
      padding: 0 4px;
    }

    &.-secondary {
      background: #45b5f5;
    }

    &.-disable {
      background: #333;
    }
  }

  &.-mypage {
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    border: 0 solid #ccc;
    border-radius: 0;
    margin: 0 auto;
    width: 380px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    &+.-mypage {
      margin-top: 24px;
      padding-bottom: 32px;
    }

    .planHeading {
      align-items: normal;
      flex-direction: column;
      margin-bottom: 16px;

      h3 {
        font-size: 20px;
        line-height: 1.3;
        font-weight: bold;
        margin-right: 0;
        padding-left: 8px;
        border-left: 3px solid #cdcdcd;
      }

      .planImage {
        max-width: none;
        max-width: 380px;
        max-height: 217px;
        margin: -24px -24px 16px;

        img {
          // max-width: 100%;
          // height: auto;
          max-width: 380px;
          max-height: 217px;
          width: 380px;
          height: 217px;
        }
      }
    }

    .planBody {
      flex-direction: column;
      justify-content: normal;

      .planBody__item {
        &+.planBody__item {
          margin-top: 8px;
        }

        &.-btn {
          margin-top: 24px;
        }
      }
    }

    .planStatus {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .planStatus__item {
        font-size: 16px;

        &.-price {
          display: flex;
          align-items: center;
          margin-top: 8px;
        }

        &+.planStatus__item {
          margin-left: 0;
        }

        .price {
          line-height: 1;
          font-weight: bold;
          font-size: 22px;

          span {
            font-size: 40px;

            @media screen and (max-width: 365px) {
              font-size: 32px;
            }
          }
        }
      }
    }

    &.-singlePlan {
      .textTruncate-text {
        &.-truncated {
          -webkit-line-clamp: unset !important;
          text-overflow: clip !important;

          &::before {
            display: none !important;
          }
        }
      }

      .textTruncate-readMore {
        display: none !important;
      }
    }
  }

  &.-suspended {
    padding: 52px 22px 32px;

    .suspendedPlanHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #333333;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      width: 100%;
      height: 32px;
      text-align: center;
    }

    .planHeading {
      .planImage {
        margin-top: -20px;
        max-width: none;
      }
    }
  }

  &.-inPlan {
    border: 2px solid #45b5f5;
    padding: 52px 22px 32px;

    .inPlanHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #45b5f5;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      width: 100%;
      height: 32px;
      text-align: center;
    }

    .planHeading {
      .planImage {
        border: 2px solid transparent;
        margin-top: -22px;
      }
    }
  }

  .trialNotice {
    padding: 0 24px;
    margin-top: 16px;
    font-size: 12px;
    color: #888;

    @include bkp(sp) {
      padding: 0 16px;
    }

    .MuiFormControlLabel-label.Mui-disabled & {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.planListLink {
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-bottom: 48px;

  a {
    font-weight: bold;
    color: #333;
    display: inline-block;
    cursor: pointer;

    &+a {
      margin-left: 32px;
    }
  }
}

.planOwnerCard {
  background: #f7f7f7;
  padding: 16px;

  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .planOwnerDoorImage {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 158px;
    margin-bottom: 16px;

    @media screen and (max-width: 480px) {
      max-height: 199px;
    }

    img {
      // max-width: 100%;
      // height: auto;
      object-fit: cover;
      max-width: 268px;
      max-height: 153px;
      width: 268px;
      height: 153px;

      @include bkp(sp) {
        max-width: 311px;
        max-height: 153px;
        width: 311px;
        height: 153px;
      }
    }
  }

  .planOwner {
    display: flex;
    align-items: center;
  }

  .planOwnerImage {
    position: relative;
    display: block;
    box-sizing: border-box;
    min-width: 88px;
    width: 88px;
    height: 88px;
    border-radius: 100%;
    border: 0 solid #fff;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .planOwnerName {
    font-size: 14px;
    color: #666;
    margin-left: 8px;
  }
}

.noticeBox {
  .noticeBoxTitle {
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  font-size: 12px;
  background: #f7f7f7;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  margin-left: 38px;

  @media screen and (max-width: 846px) {
    margin-left: 0;
    padding: 24px 16px;
  }
}
