$sp: "only screen and (max-width:640px)";
$pc: "only screen and (min-width: 641px)";
$path:"";
$main-color:#45B5F5;

$container-width:960px;
$header-height:50px;
$title-height:60px;

//base
$link-color:$main-color;
$link-text-color:$main-color;
$light-color:#f0f3f4;
$header-color:#10191d;
$bg-color:#e8eef1;
$footer-color:#505e74;
$hover-bg-color:#e8f3f7;
$border-color: #c4c4c4;
$highlight-color: #00cbd2;
$highlight-red-color: #fc1eca;

//text
$text-strong-color: #333;
$text-base-color: #333;
$text-notice-color: #888;

//social
$facebook-color:#b2b2b2;
$twitter-color:#b2b2b2;
$line-color:#b2b2b2;
$youtube-color:#b2b2b2;
$share-color:#b2b2b2;

$warning-bg-color:#fcf8e3;
$success-bg-color:#dff0d8;
$info-bg-color:#d9edf7;
$danger-bg-color:#f2dede;

$warning-text-color:#8a6d3b;
$success-text-color:#3c763d;
$info-text-color:#31708f;
$danger-text-color:#a94442;

$warning-color:#ffc107;
$success-color:#28a745;
$info-color:#11B5F5;
$danger-color:#dc3545;
$primary-color:#007bff;
$secondary-color:#6c757d;
