$contents-width: 1200px;
$bg-sub-color: #f1f8fd;
$shadow-style: 0 2px 12px 6px rgba(0, 0, 0, 0.04);
$register-color: #ffae28;
$inquiry-color: #505e74;

.global-header {
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1120px;
    margin: 0 auto;

    .header-inner__left {
      a {
        display: inline-block;
        margin-right: 32px;
        color: #333;
        font-weight: bold;

        &:hover {
          color: $main-color;
        }

        .active {
          color: $main-color;
        }

        &.isActive {
          color: #11b5f5;

          &:hover {
            color: #0aa6e3;
          }
        }

        @media #{$sp} {
          display: none;

          &:nth-child(1) {
            display: inline-block;
          }
        }
      }
    }
  }

  ul {
    align-items: center;
  }

  .loginRegister {
    display: flex;
    align-items: center;
  }

  .door-search {
    font-weight: bold;

    &:hover {
      color: $main-color;
    }
  }

  .top-login {
    font-size: 13px;
    @include bold;
    color: #333;

    @media #{$sp} {
      font-size: 11px;
      letter-spacing: -0.05em;
    }

    &:hover {
      color: $main-color;
    }
  }

  .documentRequest {
    @media #{$sp} {
      display: none;
    }
  }

  .top-documentRequest {
    font-size: 13px;
    @include bold;

    @media #{$sp} {
      font-size: 11px;
      letter-spacing: -0.05em;
    }

    background: #11b5f5;
    color: #fff;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    padding: 0 16px;

    &:hover {
      background: darken(#11b5f5, 5);
    }
  }
}

.top-container {
  background: $bg-color;

  @media #{$sp} {
    padding: 0;
  }
}

.pagetop {
  position: fixed;
  bottom: 30px;
  right: 30px;

  @media #{$sp} {
    bottom: 10px;
    right: 10px;
  }

  z-index: 100;
}

.modal-top-login {
  background: #fff;
  text-align: center;
  padding: 20px;

  .logo {
    padding: 20px 0;
  }

  .btn-round {
    display: block;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    box-sizing: border-box;
    background: $main-color;
    border: 1px solid $main-color;
    color: #fff;
    @include bold;
  }
}

.section-title {
  text-align: center;
  font-size: 32px;
  color: $text-strong-color;
  margin-bottom: 20px;

  &.section-title-white {
    color: #fff;
  }

  @media #{$sp} {
    font-size: 22px;
  }
}

.section-description {
  text-align: center;
  font-size: 15px;
  margin-bottom: 40px;

  &.section-description-white {
    color: #fff;
  }

  @media #{$sp} {
    margin-bottom: 32px;
    text-align: left;
    line-height: 1.6;
  }
}

.section-base {
  padding: 36px 24px;

  @media only screen and (max-width: 1024px) {
    padding: 36px 16px;
  }
}

.section-inner {
  max-width: $contents-width;
  margin: 0 auto;
  z-index: 1;

  @media #{$sp} {
    width: 100%;
  }
}

.section-mv {
  position: relative;
  height: 496px;
  overflow: hidden;
  background-color: #fff;

  &:before {
    content: '';
    background: url(../images/top/mv_02-parts_02.svg) no-repeat;
    background-position: left bottom;
    background-size: 662px 177px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:after {
    content: '';
    background: url(../images/top/mv_02-parts_01.svg) no-repeat;
    background-position: right top;
    background-size: 928px 114px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media #{$sp} {
    position: relative;
    text-align: center;
    height: auto;
    padding: 56px 16px 30px;

    &::before {
      content: '';
      background: url(../images/top/mv_01-parts_sp.svg) no-repeat;
      background-position: top right;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:after {
      display: none;
    }
  }

  .section-mv-inner {
    height: 496px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
    background-image: url(../images/top/mv_02.svg);
    background-size: 75% 75%;
    background-repeat: no-repeat;
    background-position: 140% center;
    z-index: 1;

    @media #{$sp} {
      height: inherit;
      width: 100%;
      background: none;
    }

    .section-inner {
      max-width: 1120px;
      margin-top: -72px;
      width: 100%;

      @media #{$sp} {
        width: 100%;
        margin-top: 0;
      }
    }

    .btn-wrap {
      margin-top: 40px;
      display: flex;
      align-items: center;

      @media #{$sp} {
        flex-direction: column;
      }
    }

    .btn-twitter {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // .section-mv {
  //   height: 500px;
  //   overflow: hidden;
  //   background-color: $main-color;
  //   .section-mv-inner {
  //     height: 500px;
  //     display: flex;
  //     align-items: center;
  //     position: relative;
  //     width: $contents-width;
  //     margin: 0 auto;
  //     @media #{$sp} {
  //       height: inherit;
  //       width: 100%;
  //     }
  //   }
  .pc-mv {
    // position: absolute;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // background-image: url(../images/top/mv_02.svg);
    // background-size: 80% 80%;
    // background-repeat: no-repeat;
    // background-position: 170% center;
    display: none;

    @media #{$sp} {
      display: none;
    }
  }

  // .pc-mv {
  //   position: absolute;
  //   top: 0;
  //   left: 390px;
  //   width: 1039px;
  //   height: 560px;
  //   background-image: url(../images/top/mv.png);
  //   background-size: 1039px 560px;
  //   background-repeat: no-repeat;
  //   @media #{$sp} {
  //     display: none;
  //   }
  // }
  h1 {
    position: relative;
    font-size: 40px;
    margin-bottom: 10px;

    //text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    @media #{$sp} {
      font-size: 24px;
      text-align: center;
      margin-bottom: 32px;

      span {
        font-size: 13px;
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 1.5;

    //text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    @media #{$sp} {
      font-size: 18px;
      text-align: left;
      margin-top: 32px;
      font-weight: bold;
    }
  }

  .sub {
    width: 400px;
    text-align: center;
    font-size: 16px;
    @include bold;

    //text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    @media #{$sp} {
      width: 100%;
      font-size: 14px;
    }
  }
}

.section-opendoor {
  position: relative;
  overflow: hidden;
  background: url(../images/top/bg_opendoor.svg) no-repeat, $main-color;
  background-size: cover;
  background-position: center;

  @media #{$sp} {
    background: url(../images/top/bg_opendoor_sp.svg) no-repeat, $main-color;
    background-size: cover;
    background-position: center;
    padding: 24px;
  }

  .section-opendoor-inner {
    height: 414px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;

    @media #{$sp} {
      height: inherit;
      width: 100%;
    }
  }

  .section-inner {
    text-align: center;
    margin: 0;
  }

  .btn-wrap {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }

  .btn-twitter {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1 {
    position: relative;
    font-size: 31px;
    margin-bottom: 8px;
    color: #fff;

    @media #{$sp} {
      font-size: 21px;
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }

  .description {
    font-size: 14px;
    line-height: 2;
    color: #fff;

    //text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    @media #{$sp} {
      font-size: 14px;
      line-height: 1.8;
      text-align: left;
      font-weight: bold;
    }
  }

  .sub {
    text-align: center;
    font-size: 16px;
    @include bold;
    color: #fff;

    @media #{$sp} {
      width: 100%;
      font-size: 14px;
    }
  }

  .register {
    margin-top: 60px;

    @media #{$sp} {}
  }
}

.register {
  position: relative;
  margin-bottom: 20px;
  margin-top: 80px;
  display: flex;
  justify-content: center;

  @media #{$sp} {
    margin-top: 40px;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;

    .section-mv & {
      margin-top: 80px;
    }
  }

  &.register-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn-register {
  position: relative;
  display: block;
  width: 260px;
  height: 56px;
  line-height: 56px;
  border-radius: 32px;
  background: $register-color;
  color: #fff;
  text-align: center;
  font-size: 16px;
  @include bold;
  transition: background-color 0.2s;

  @media #{$sp} {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    max-width: 300px;
  }

  &:hover {
    background-color: darken($register-color, 10);
  }

  &+a {
    margin-left: 40px;

    @media #{$sp} {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  img {
    position: absolute;
    top: -50px;
    left: -40px;

    @media #{$sp} {
      top: -40px;
      left: -20px;
      width: 80px;
      height: 80px;
    }
  }

  &.btn-default {
    background: #1198f2;
    border: none;
    color: #fff;

    &:hover {
      background: lighten(#1198f2, 5);
    }
  }
}

.btn-owner {
  position: relative;
  display: block;
  width: 200px;
  height: 46px;
  line-height: 46px;
  border-radius: 5px;
  background: $main-color;
  color: #fff;
  text-align: center;
  font-size: 14px;
  @include bold;
  transition: background-color 0.2s;

  @media #{$sp} {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    max-width: 300px;
    border-radius: 40px;
  }

  &:hover {
    background-color: darken($main-color, 10);
  }

  &+a {
    margin-left: 24px;

    @media #{$sp} {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &.btn-default {
    background: $link-color;
    border: none;
    color: #fff;

    &:hover {
      background: lighten($link-color, 5);
    }
  }
}

.btn-beginner {
  position: relative;
  display: block;
  width: 200px;
  height: 46px;
  line-height: 46px;
  border-radius: 5px;
  background: #fff;
  color: $main-color;
  text-align: center;
  border: 1px solid $main-color;
  font-size: 14px;
  @include bold;
  transition: background-color 0.2s;

  @media #{$sp} {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    max-width: 300px;
    border-radius: 40px;
  }

  &:hover {
    opacity: 0.8;
  }

  &+a {
    margin-left: 24px;

    @media #{$sp} {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &.btn-default {
    background: $link-color;
    border: none;
    color: #fff;

    &:hover {
      background: lighten($link-color, 5);
    }
  }
}

.btn-inquiry {
  display: block;
  width: 400px;
  height: 64px;
  line-height: 64px;
  border-radius: 32px;
  background-color: $inquiry-color;
  color: #fff;
  text-align: center;
  font-size: 20px;
  @include bold;
  transition: background-color 0.2s;

  @media #{$sp} {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    max-width: 300px;
  }

  &:hover {
    background-color: darken($inquiry-color, 10);
  }
}

.section-nav {
  background: $bg-sub-color;

  @media #{$sp} {
    display: none;
  }

  ul {
    width: $contents-width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      a {
        display: block;
        padding: 20px 24px;
        text-align: center;
        color: #414141;
        @include bold;
        transition: background-color 0.2s;

        &:hover {
          background: #fff;
        }
      }
    }
  }
}

.section-banner {
  background: #fff;
  padding-bottom: 0;
  padding-top: 64px;

  @media #{$sp} {
    padding: 0 10px;
    padding-top: 40px;
  }

  &.-slick {
    line-height: 0;
    overflow: hidden;

    .swiper-button-prev,
    .swiper-button-next {
      color: #ccc;
    }

    @media #{$sp} {

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .swiper-container-banner .swiper-slide .thumb a {
        padding: 0 8px;
      }
    }
  }

  .section-bannerInner {
    // width: $contents-width;
    width: 952px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media #{$sp} {
      width: 100%;
      display: block;
    }
  }

  &+.section-banner {
    padding-top: 32px;

    @media #{$sp} {
      padding-top: 12px;
    }
  }

  p {
    line-height: 0;

    @media #{$sp} {
      &+p {
        margin-top: 12px;
      }
    }
  }

  a {
    display: block;
  }

  img {
    @media #{$sp} {
      width: 100%;
      height: 100%;
    }
  }
}

.section-recommend {
  background: #fff;

  &.-top {
    height: auto;
  }

  @media #{$sp} {
    &.-top {
      height: auto;
    }
  }

  .list-recommend {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px 16px;

    @media (max-width: 920px) {
      margin: 0 -8px 16px;
    }

    li {
      position: relative;
      width: 20%;
      padding: 8px;

      @media (max-width: 920px) {
        width: 25%;
      }

      @media (max-width: 768px) {
        width: 33.3%;
      }

      @media (max-width: 568px) {
        width: 50%;
      }

      &:nth-child(n+13) {
        @media #{$sp} {
          display: none;
        }
      }

      .list-recommend__item {
        display: block;
        border-radius: 12px;
        background: #fff;
        box-shadow: $shadow-style;
        transition: box-shadow 0.2s;
        border-radius: 11px;
        overflow: hidden;

        @media #{$sp} {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        }
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #bbb;
        position: relative;

        .batch {
          position: absolute;
          left: 14px;
          bottom: -10px;
          z-index: 3;
          color: #fff;
          font-size: 10px;
          background: #2bd6a7;
          border: 1px solid #fff;
          display: inline-block;
          min-width: 72px;
          height: 18px;
          padding: 0 8px;
          line-height: 18px;
          text-align: center;
          border-radius: 20px;
          font-weight: bold;

          @media screen and (-webkit-min-device-pixel-ratio: 0) {

            _::-webkit-full-page-media,
            _:future,
            :root & {
              line-height: 16px;
            }
          }

          @media only screen and (max-width: 480px) {
            left: 6px;
          }
        }

        img {
          object-fit: cover;
          max-width: 100%;
          max-height: 168px;
          width: 100%;
          aspect-ratio: 7/4;
        }
      }

      .body {
        padding: 16px 12px 12px;

        @media #{$sp} {
          padding: 14px 8px 8px;
        }

        .title {
          color: $text-strong-color;
          font-size: 14px;
          @include bold;
          margin-bottom: 12px;
          height: 36px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.3;

          @media #{$sp} {
            font-size: 12px;
            height: 32px;
            margin-bottom: 8px;
          }
        }

        .user {
          display: flex;
          align-items: center;

          .icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #bbb;
            overflow: hidden;
            border-radius: 100%;
            margin-right: 8px;
            flex-shrink: 0;

            @media #{$sp} {
              width: 32px;
              height: 32px;
            }

            img {
              max-width: 100%;
              width: 32px;
              height: 32px;
              object-fit: cover;
            }
          }

          .sub {
            color: $text-strong-color;
            font-size: 11px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media #{$sp} {
              font-size: 11px;
              max-height: 30px;
            }
          }
        }
      }
    }
  }
}

.section-about {
  background: $bg-sub-color;

  .section-inner {
    width: 960px;

    @media #{$sp} {
      width: 100%;
    }
  }

  .chart {
    background: #fff;
    padding: 60px;
    box-shadow: $shadow-style;
    border-radius: 12px;

    @media #{$sp} {
      padding: 20px 10px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.section-feature {
  background: #fff;

  .list-feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 800px;
    box-sizing: border-box;

    @media #{$sp} {
      padding: 10px;
      width: 100%;
      display: block;
      padding-bottom: 0;

      &+.list-feature {
        padding-top: 0;

        li {

          &:nth-child(2),
          &:nth-child(3) {
            .thumb {
              img {
                max-height: 36px;
              }
            }
          }
        }
      }
    }

    li {
      text-align: center;
      width: 240px;
      margin: 0 0 30px 0;

      @media #{$sp} {
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        margin: 0 0 20px 0;
      }

      .thumb {
        display: flex;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 100%;
        box-sizing: border-box;
        padding: 20px;
        border: 4px solid $main-color;
        margin: 0 auto 20px auto;

        @media #{$sp} {
          width: 60px;
          height: 60px;
          padding: 5px;
          margin: 0 20px 0 0;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        flex: 1;
      }

      .title {
        color: $text-strong-color;
        font-size: 20px;
        @include bold;
        margin-bottom: 10px;

        @media #{$sp} {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .sub {
        color: $text-base-color;
        font-size: 14px;
      }
    }
  }
}

.section-ownerPoint {
  background: #fff;

  .list-ownerPoint {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 800px;
    box-sizing: border-box;

    @media #{$sp} {
      padding: 10px;
      width: 100%;
      display: block;
      padding-bottom: 0;
    }

    &+.list-ownerPoint {
      width: 520px;

      @media #{$sp} {
        width: 100%;
        padding-top: 0;
        padding-bottom: 10px;
      }
    }

    li {
      text-align: center;
      width: 240px;
      margin: 0 0 30px 0;

      @media #{$sp} {
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        margin: 0 0 20px 0;
      }

      .thumb {
        display: flex;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 100%;
        box-sizing: border-box;
        padding: 20px;
        border: 4px solid $main-color;
        margin: 0 auto 20px auto;

        @media #{$sp} {
          width: 60px;
          height: 60px;
          padding: 5px;
          margin: 0 20px 0 0;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        flex: 1;
      }

      .title {
        color: $text-strong-color;
        font-size: 20px;
        @include bold;
        margin-bottom: 10px;

        @media #{$sp} {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .sub {
        color: $text-base-color;
        font-size: 14px;
      }
    }
  }
}

.section-usage {
  position: relative;
  background-color: #11b3f3;
  background-image: url(../images/top/bg_image.svg);
  background-position: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  .list-usage-sp {
    display: none;
    padding-bottom: 40px;

    @media #{$sp} {
      display: block;
    }

    text-align: center;

    .title {
      color: #fff;
      margin-bottom: 20px;
      font-size: 18px;
      @include bold;
      display: inline-block;
      border-bottom: 3px solid #fff;
    }

    .thumb {
      padding: 10px 30px;

      img {
        display: block;
        width: 100%;
        height: inherit;
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }

  .list-usage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 700px;

    @media #{$sp} {
      display: none;
    }

    li {
      text-align: center;
      width: 340px;
      margin: 0 0 40px 0;

      .thumb {
        margin-bottom: 10px;

        img {
          margin: 0 auto;
          display: block;
          width: 300px;
        }
      }

      .title {
        color: #fff;
        margin-bottom: 20px;
        font-size: 20px;
        @include bold;
        display: inline-block;
        border-bottom: 3px solid #fff;
      }
    }
  }
}

.section-step {
  background: #fff;

  .list-step {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 630px;

    @media #{$sp} {
      display: block;
      width: 100%;
    }

    li {
      width: 300px;
      margin: 0 0 30px 0;
      box-shadow: $shadow-style;
      border-radius: 10px;
      overflow: hidden;

      @media #{$sp} {
        width: 100%;
        max-width: 320px;
        margin: 0 auto 10px auto;
      }

      .thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $main-color;
        box-sizing: border-box;

        img {
          width: 140px;
          height: 160px;
          display: block;

          @media #{$sp} {
            width: 120px;
            height: 120px;
          }
        }
      }

      &:nth-child(2) {
        .thumb {
          img {
            padding: 5px;
          }
        }
      }

      .text {
        padding: 16px;
      }

      .title {
        color: $text-strong-color;
        font-size: 20px;
        @include bold;
        margin-bottom: 10px;
        display: flex;
        justify-content: left;
        align-items: center;

        @media #{$sp} {
          font-size: 16px;
        }

        img {
          margin-right: 6px;
        }
      }

      .sub {
        color: $text-base-color;
        font-size: 14px;

        @media #{$sp} {
          font-size: 14px;
        }
      }
    }
  }
}

.section-activity {
  background: $bg-sub-color;

  .list-step {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;

    @media #{$sp} {
      display: block;
      margin: 0;
    }

    li {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      text-align: center;
      box-shadow: $shadow-style;
      width: calc((100% / 3) - (60px / 3));

      @media #{$sp} {
        width: 100%;
        max-width: 320px;
        margin: 0 auto 10px auto;
        padding: 10px 20px 20px;
      }

      .thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        img {
          width: 140px;
          height: 160px;
          display: block;

          @media #{$sp} {
            width: 120px;
            height: 120px;
          }
        }
      }

      .text {
        flex: 1;
      }

      .title {
        color: $text-strong-color;
        font-size: 20px;
        @include bold;
        margin-bottom: 10px;

        @media #{$sp} {
          margin-bottom: 4px;
          font-size: 16px;
        }
      }

      .sub {
        color: $text-base-color;
        font-size: 14px;

        @media #{$sp} {
          font-size: 14px;
          letter-spacing: -0.05em;
          text-align: left;
        }
      }
    }
  }

  &.-mypage {
    background: transparent;

    .list-step {
      display: flex;
      flex-wrap: nowrap;
      justify-content: normal;
      margin: 0;

      li {
        background: transparent;
        width: 100%;
        border-radius: 0;
        padding: 0 20px 40px;
        box-shadow: none;

        @media #{$sp} {
          max-width: none;
          height: auto;
        }
      }
    }
  }
}

.section-revenue {
  background: #fff;

  .chart {
    background: #fff;

    //padding: 10px 0;
    //box-shadow: $shadow-style;
    //border-radius: 12px;
    img {
      display: block;
      width: 100%;

      &.spImage {
        display: none;
      }

      @media #{$sp} {
        &.pcImage {
          display: none;
        }

        &.spImage {
          display: block;
        }
      }
    }
  }
}

.section-faq {
  background: $bg-sub-color;

  .list-faq {
    .acc-checkbox {
      display: none;
    }

    li {
      padding: 20px;
      border-radius: 8px;
      background: #fff;
      box-shadow: $shadow-style;

      @media #{$sp} {
        padding: 16px;
      }

      &+li {
        margin: 16px 0 0 0;
      }
    }

    .acc-title {
      display: flex;
      color: $text-strong-color;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      background-image: url(../images/top/button_open.svg);
      background-size: 30px 24px;
      background-repeat: no-repeat;
      background-position: 100% 50%;
      padding-right: 36px;

      @media #{$sp} {
        font-size: 15px;
        background-size: 16px 16px;
      }

      img {
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .acc-contetns {
      height: 0;
      padding: 0;
      overflow: hidden;
      transition: opacity 0.5s;
      opacity: 0;
      visibility: hidden;

      .text {
        display: flex;
        line-height: 1.6;
        color: $text-base-color;
        font-size: 14px;

        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    .acc-checkbox:checked+.acc-title {
      background-image: url(../images/top/button_close.svg);
    }

    .acc-checkbox:checked+.acc-title+.acc-contetns {
      border-top: 1px solid $border-color;
      margin-top: 20px;
      padding-top: 20px;
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}

.section-start {
  background: #fff;
  padding-bottom: 80px;

  &.sp-only {
    padding-bottom: 40px;
  }
}

.section-keyword {
  background: #fff;
  padding: 0 0 60px;

  @media #{$sp} {
    padding: 0 0 8px;
  }

  &.-search {
    padding-top: 40px;
    padding-bottom: 8px;

    @media #{$sp} {
      padding-top: 24px;
      padding-bottom: 1px;
    }

    &+&.-search {
      padding-top: 24px;
      padding-bottom: 24px;

      @media #{$sp} {
        padding-top: 16px;
        padding-bottom: 1px;
      }
    }

    .keyword-search {
      padding: 0 24px;
    }
  }

  &.-notFound {
    padding: 64px 0;

    @media #{$sp} {
      width: 100%;
      padding: 24px 0;
    }

    .section-inner {
      width: 940px;
      margin: 0 auto;

      @media #{$sp} {
        width: 100%;
        padding: 0 16px;
      }

      .breadcrumbs .inner {
        width: auto;
      }

      .category {
        font-size: 20px;
        font-weight: bold;
        margin-left: 4px;

        @media #{$sp} {
          font-size: 18px;
        }
      }

      p {
        text-align: center;
        color: #888888;
        font-size: 24px;
        font-weight: bold;
        padding: 80px 0;

        @media #{$sp} {
          padding: 40px 0;
          font-size: 18px;
        }
      }
    }
  }
}

.keyword-search {
  max-width: 945px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;

  &.-fullSize {
    width: 100%;
  }

  &.-top {
    height: 68px;
    overflow: hidden;
    padding: 0 16px;
    margin-bottom: 24px;

    @media #{$sp} {
      height: auto;
      margin-bottom: 0;
    }
  }

  @media #{$sp} {
    width: 100%;
    padding: 0;
  }

  .keyword-search__item {
    height: 30px;
    border: 1px solid #333;
    border-radius: 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-bottom: 25px;
    }

    @media #{$sp} {
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 13px;
    }

    a {
      display: block;
      padding: 5px 12px;
      color: #333;
    }
  }
}

.btnList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 40px auto 0;

  @media #{$sp} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: -16px auto 0;
  }

  .btnList__item {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$sp} {
      width: 75%;
    }

    a {
      color: #333;
      text-decoration: underline;
    }

    .btn-register {
      margin-top: 48px;
      margin-bottom: 24px;
      color: #fff;
      text-decoration: none;

      img {
        position: absolute;
        top: -50px;
        left: -40px;
      }
    }

    .btn-inDoor {
      text-decoration: none;
      margin-top: 48px;
      margin-bottom: 24px;
      position: relative;
      display: block;
      width: 260px;
      height: 56px;
      line-height: 56px;
      background: #fff;
      color: $main-color;
      text-align: center;
      border: 1px solid $main-color;
      font-size: 16px;
      @include bold;
      transition: background-color 0.2s;
      border-radius: 32px;

      @media #{$sp} {
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        width: 100%;
        max-width: 300px;
        margin-top: 0;
        border-radius: 40px;
      }

      &:hover {
        border-color: darken($main-color, 10);
      }
    }
  }

  &+.btnList {
    flex-direction: row;

    @media #{$sp} {
      margin-top: 0;
    }

    .btnList__item {
      width: 50%;
    }
  }
}

.section-beginner {
  background: #fff;
  padding-top: 80px;
  padding-bottom: 24px;

  @media #{$sp} {
    padding: 24px 16px;
  }

  .section-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    @media #{$sp} {
      p {
        text-align: center;

        img {
          width: 80%;
        }
      }
    }
  }

  h1 {
    font-size: 17px;
    margin-bottom: 24px;

    @media #{$sp} {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    font-weight: 500;

    @media #{$sp} {
      font-size: 17px;
      line-height: 1.6;
      text-align: left;
      margin-bottom: 48px;
      font-weight: bold;
    }
  }
}

.appeal-point {
  display: flex;
  justify-content: center;
  margin: 56px 0;

  @media #{$sp} {
    flex-direction: column;
    margin: auto;
  }

  .appeal-point__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    .ballon {
      background-image: url(../images/top/balloon.svg);
      background-size: 180px 67px;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 67px;
      font-size: 15px;
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      margin-top: 8px;
    }
  }
}

.section-feature {
  background: #fff;
}

.section-beginnerPoint {
  background: #fff;

  @media #{$sp} {
    background: $main-color url(../images/top/beginner-image_02_sp.svg) no-repeat;
    background-size: cover;
    background-position: center 20px;
  }
}

.feature {
  width: 880px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media #{$sp} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .feature__item {
    flex-basis: 33.3%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;

    @media #{$sp} {
      flex-basis: 100%;
      flex-direction: row;
      text-align: left;
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: 0px solid #e8e8e8;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .feature-icon {
      width: 112px;
      height: 112px;
      box-shadow: 0 2px 6px #0000002b;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      @media #{$sp} {
        width: 72px;
        height: 72px;
        margin-right: 16px;
        margin-bottom: 0;
        flex-shrink: 0;

        img {
          width: 40px;
          height: 48px;
        }
      }
    }

    .feature-text {
      h3 {
        font-size: 15px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

.feature-point {
  width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media #{$sp} {
    width: 100%;
    flex-direction: column;
  }

  .feature-point__left {
    width: 364px;

    @media #{$sp} {
      width: 100%;
    }

    h3 {
      font-size: 32px;
      margin-bottom: 16px;
      position: relative;

      @media #{$sp} {
        color: #fff;
        font-size: 28px;
        text-align: center;
      }

      &.point {
        &::before {
          content: '';
          background-image: url(../images/top/balloon_01.svg);
          background-size: 87px 64px;
          background-repeat: no-repeat;
          width: 87px;
          height: 64px;
          position: absolute;
          left: -78px;
          top: -50px;

          @media #{$sp} {
            display: none;
          }
        }
      }
    }

    p {
      font-size: 14px;

      @media #{$sp} {
        color: #fff;
        font-weight: bold;
        margin-bottom: 16px;
        padding: 0 16px;
      }
    }
  }

  .feature-point__right {
    @media #{$sp} {
      width: 90%;
      margin: 0 auto;
    }

    h3 {
      font-size: 32px;
      margin-bottom: 16px;
      position: relative;
    }

    p {
      font-size: 14px;
    }

    img {
      @media #{$sp} {
        width: 100%;
      }
    }

    .notice {
      @media #{$sp} {
        color: #fff;
        margin-top: 8px;
      }
    }
  }
}

.owner-point {
  width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media #{$sp} {
    width: 100%;
    flex-direction: column;
  }

  .owner-point__left {
    width: 50%;

    @media #{$sp} {
      width: 100%;
      text-align: center;
      margin-bottom: 16px;
    }

    h3 {
      @media #{$sp} {
        font-size: 26px;
        text-align: center;
        margin-bottom: 16px;
      }
    }

    img {
      width: 90%;

      @media #{$sp} {
        width: 80%;
      }
    }
  }

  .owner-point__right {
    width: 50%;

    @media #{$sp} {
      width: 100%;
    }

    h3 {
      font-size: 28px;
      margin-bottom: 16px;
      position: relative;
    }

    p {
      font-size: 14px;

      @media #{$sp} {
        font-size: 17px;
        line-height: 1.6;
        font-weight: bold;
        padding: 0 16px;
      }
    }
  }
}

.ownerPoint {
  width: 880px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media #{$sp} {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  }

  .ownerPoint__item {
    flex-basis: 33.3%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;

    @media #{$sp} {
      flex-basis: 100%;
      flex-direction: row;
      text-align: left;
      margin-bottom: 12px;
    }

    .ownerPoint-icon {
      width: 112px;
      height: 112px;
      box-shadow: 0 2px 6px #0000002b;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      @media #{$sp} {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        margin-bottom: 0;
        flex-shrink: 0;
        box-shadow: none;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .ownerPoint-text {
      h3 {
        font-size: 15px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

.section-postSample {
  background: #fff;
  min-height: 600px;

  @media #{$sp} {
    height: 570px;
  }

  .section-inner {
    max-width: $contents-width;
    display: flex;

    @media #{$sp} {
      width: 100%;
    }
  }

  .postSampleWrap {
    box-sizing: border-box;
    justify-content: center;
  }

  .swiper-container {
    padding: 0 80px;

    // overflow: initial;
    @media #{$sp} {
      padding: 0;
    }
  }

  .swiper-wrapper {
    box-sizing: border-box;
  }

  .swiper-slide {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #ccc;
  }

  .swiper-button-prev {
    left: 40px;
  }

  .swiper-button-next {
    right: 40px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @media #{$sp} {
      display: none;
    }
  }

  .postSample {
    width: 100%;
    // max-width: 368px;
    min-height: 428px;
    overflow: hidden;
    box-shadow: 0 2px 12px #0000001a;
    padding: 16px;
    border-radius: 10px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    .post-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .postIcon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #bbb;
        overflow: hidden;
        border-radius: 100%;
        margin-right: 8px;
        flex-shrink: 0;

        @media #{$sp} {
          width: 32px;
          height: 32px;
        }

        img {
          max-width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .post-title {
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .postName {
        .postName__door {
          font-weight: bold;
          font-size: 14px;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .postName__user {
          color: #333;
          font-size: 12px;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }

    .post-body {
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;

      &.-textOnly {
        -webkit-line-clamp: 16;
      }

      img {
        max-height: 199px;
      }

      .media {
        margin-bottom: 20px;

        li {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          img {
            border: 1px solid #c4cfd6;
            border-radius: 4px;
            overflow: hidden;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        &.-detail {
          li {
            padding-top: 0;

            img {
              position: static;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: -10px;
    position: initial;
  }
}
