@use "sass:math";

$form-height: 56px;
$form-border-radius: 8px;

.form-block{
    padding: 0 0 24px 0;
    @media #{$sp} {
        padding: 0 0 16px 0;
    }
    .errorText {
        color: #ff6d6d;
    }
    .form-block__inner {
        padding: 0 21px;
    }
    .showPassword {
        svg {
            cursor: pointer;
        }
    }
    .setting-title {
        margin-bottom: 24px;
        .MuiSvgIcon-root {
            vertical-align: -7px;
            width: 24px !important;
            height: 24px !important;
        }
    }
    .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .btn.btn-keywordSelect {
        border-color: #c4c4c4;
    }
}

.form-submit{
    text-align: center;
    padding: 20px 0 0 0;
}

.input-label{
    display: block;
    font-size: 14px;
    margin: 0 0 4px 2px;
    color: $text-base-color;
    @include bold;
    .required{
        margin-left: 10px;
        font-size: 10px;
        color: white;
        background: #ff6d6d;
        padding: 2px 7px 1px;
        border-radius: 3px;
    }
}

.input-group{
    position:relative;
    display: flex;
    align-items: center;
    justify-content:center;
    .item{
        padding: 0 2px;
    }
    .item-grow{
        padding: 0 2px;
        flex-grow : 1;
    }
    .select-control{
        select{
            width: auto;
        }
    }
    .input-control {
        min-height: 44px;
        line-height: 44px;
    }
}

.radio{
    display: inline-block;
    margin: 10px;
    input{
        display: inline-block;
        margin-right: 6px;
    }
}

.input-view{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: $form-height;
    line-height: $form-height;
    border-radius: $form-border-radius;
    padding: 0 8px;
    background: $light-color;
    font-size: 14px;
}

.input-cost{
    white-space: nowrap;
    padding: 0 8px;
    vertical-align: bottom;
    font-size: 16px;
    @include bold;
}
.input-control {
    font-size: 16px;
    display: block;
    width: 100%;
    min-height: 56px;
    line-height: 56px;
    margin: 0;
    color: $text-strong-color;
    outline: none;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-appearance: none;
    @media #{$sp} {
        min-height: 40px;
        line-height: 40px;
    }
    &.MuiInputBase-root {
        border-radius: 8px;
    }
    &.-fixed {
        background: #eee;
        border-radius: 8px;
        padding: 0 16px;
    }
    &.-borderLess {
        input {
            border: 0;
            box-shadow: none;
        }
    }
}
.input-control {
    input{
        font-size: 16px;
        display: block;
        width: 100%;
        height: 56px;
        line-height: 1;
        padding: 13px 16px;
        margin: 0;
        color: $text-strong-color;
        background-color: #fff;
        border: 1px solid $border-color;
        outline: none;
        border-radius: $form-border-radius;
        box-sizing: border-box;
        word-break: break-all;
        box-shadow: none;
        -webkit-appearance: none;
        @media #{$sp} {
            height: 40px;
            min-height: 40px;
            padding: 0 16px;
        }
    }
    &.-oneline {
        input {
            padding: 13px 16px 22px;
            min-height: 67px;
        }
    }
    &.-price {
        input {
            padding: 13px 16px;
        }
    }
    .MuiOutlinedInput-root {
        border-radius: $form-border-radius;
    }
}
.input-control textarea{
    font-size: 16px;
    display: block;
    line-height: 1.3;
    padding: 15px 16px;
    margin: 0;
    color: $text-strong-color;
    outline: none;
    min-height: 50px;
    box-sizing: border-box;
    box-shadow: none;
    -webkit-appearance: none;
}

textarea.input-control{
    height: 120px;
    padding: 10px 16px;
    line-height: normal;
    border: 1px solid $border-color;
}
.input-controlText {
    width: 100%;
    min-height: $form-height;
    line-height: $form-height;
    .MuiOutlinedInput-multiline {
        background-color: #fff;
        border-radius: 8px;
        font-size: 16px;
        display: block;
        width: 100%;
        min-height: $form-height;
        line-height: 1.5;
        margin: 0;
        padding: 13px 16px;
        color: $text-strong-color;
        outline: none;
        box-sizing: border-box;
        -webkit-appearance: none;
    }
}
.post {
    width: 100%;
    >div {
        &::before,
        &::after {
            display: none;
        }
    }
}
.post-footer {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .post-footer__right {
        display: flex;
        align-items: center;
    }
    .btn {
        margin-left: 16px;
        height: 37px;
        line-height: 37px;
        padding: 0 22px;
    }
}
.post textarea{
    height: 220px;
    padding: 10px;
    font-size: 16px;
    display: block;
    width: 100%;
    padding: 10px 16px;
    margin: 0;
    color: $text-strong-color;
    background-color: #fff;
    border: 1px solid $border-color;
    outline: none;
    border-radius: $form-border-radius;
    box-sizing: border-box;
    line-height: 1.5;
    box-sizing: border-box;
    -webkit-appearance: none;
    .newPost & {
        min-height: 160px;
        word-break: break-all;
    }
}

.btn{
    display: inline-block;
    padding: 0 24px;
    border-radius: 6px;
    height: $form-height;
    line-height: $form-height;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    @include bold;
    font-size: 14px;
    background: none;
    cursor: pointer;
    &:focus {
        outline: 0;
    }
    svg{
        vertical-align: middle;
        color: #fff;
        height: 16px;
        width: 16px;
        margin-top: -2px;
    }
    &:disabled {
        background: #eee;
        color: rgba(0, 0, 0, 0.26);
        cursor: default;
        pointer-events: none;
    }
}

.btn-sm{
    padding: 0 10px;
    border-radius: 3px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
}

.btn-round{
    border-radius: math.div($form-height, 2);
}

.btn-default{
    background: $link-color;
    border:none;
    color: #fff;
    &:hover{
        background: lighten($link-color,5);
    }
}

.btn-disabled {
    background: #eee;
    color: rgba(0, 0, 0, 0.26);
    // cursor: default;
    // pointer-events: none;
    border: 0;
}

.btn-danger{
    background: $danger-color;
    border:none;
    color: #fff;
    &:hover{
        background: lighten($danger-color,5);
    }
}

.btn-cancel{
    background: $secondary-color;
    border:none;
    color: #fff;
    &:hover{
        background: lighten($secondary-color,5);
    }
}

.btn-submit{
    background: $link-color;
    border:none;
    color: #fff;
    &:hover{
        background: darken($link-color,5);
    }
}
.btn-abort{
    border:none;
    color: gray;
    &:disabled{
        background: none;
    }
}



.btn-twitter{
    background: #00aced;
    border:none;
    color: #fff;
    &:hover{
        background: lighten(#00aced,5);
    }
}
// #2475の問題を回避するために暫定で作成,#2533で使用
// btn-twitterから問題を引き起こすhoverを除いたもの
.btn-twitter-sp {
    background: #00aced;
    border:none;
    color: #fff;
}

.btn-block{
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
}

.btn-fixed{
    width: 240px;
    @media #{$sp} {
        width: 100%;
    }
}

.btn-post{
    position: fixed;
    bottom:20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    @media only screen and (min-width: 847px) {
        display: none;
        // bottom: 40px;
        // right: 50%;
        // margin-right: -400px;
    }
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-radius: 100%;
        background: #26bfdb;
        color:#fff;
        box-shadow: 0 2px 12px 6px rgba(0, 0, 0, .08);
        .material-icons{
            font-size: 32px;
            width: 32px;
        }
        &:hover{
            color: #fff;
        }
    }
    svg {
        font-size: 32px !important;
        width: 32px !important;
    }
}

select::-ms-expand {
    display: none;
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-control{
    background-color: #fff;
    border-radius: $form-border-radius;
    overflow: hidden;
    position: relative;
    border: 1px solid $border-color;
    box-sizing: border-box;
    padding-right: 18px;
    white-space: nowrap;
    font-weight: normal;
    height: $form-height;
    @media #{$sp} {
        padding-right : 12px;
    }
    &.select-control-text{
        padding: 0 6px;
        line-height: $form-height;
    }
    select{
        height: $form-height;
        line-height: $form-height;
        padding: 0 6px;
        margin: 0;
        width: 100%;
        box-sizing:border-box;
        font-size: 16px;
        font-weight: normal;
        display: block;
        color: $text-base-color;
        white-space: nowrap;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        @media #{$sp} {
            font-size: 16px;
        }
    }
    &:before {
        position: absolute;
        top: math.div($form-height, 2) - 2px;
        right: 6px;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $link-color;
        pointer-events: none;
    }
}

.upload{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $border-color;
    border-radius: $form-border-radius;
    color:#fff;
    height: 120px;
    .material-icons{
        font-size: 32px;
        width: 32px;
    }
}

.text-count{
    text-align: right;
    color: #a2a2a2;
    strong{
        font-size: 20px;
        @include bold;
        color:$link-color;
    }
}

/* Rounded */
.input-checkbox-block {
    display: flex;
    align-items: center;
    .label{
        @include bold;
    }
    .checkbox{
        margin-right: 10px;
        width: 64px;
        height: 32px;
        position: relative;
        input {
            opacity: 0;
        }
        label {
            position: absolute;
            background-color: $border-color;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 16px;
            -webkit-transition: background-color .2s ease-in-out;
                    transition: background-color .2s ease-in-out;
        }
        label:after {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            cursor: pointer;
            position: absolute;
            top: 4px;
            z-index: 1;
            left: 4px;
            background-color: #fff;
            -webkit-transition: left .2s ease-in-out;
                    transition: left .2s ease-in-out;
        }

        input[type=checkbox]:checked ~ label {
            background-color: $main-color;
        }
        input[type=checkbox]:checked ~ label:after {
            left: 36px;
        }
    }
}

.hr-or{
    padding: 40px 0;
    color: #444;
    font-size: 12px;
    display: flex;
    align-items: center;
    &:before, &:after {
        content: "";
        flex-grow: 1;
        height: 1px;
        background: $border-color;
        display: block;
    }
    &:before {
        margin-right: .4em;
    }
    &:after {
        margin-left: .4em;
    }
}

.btn-photo{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .material-icons{
        font-size: 32px;
        width: 32px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    svg {
        color: white;
    }
}

// MUIの切り替えスイッチ共通
%muiSwitch {
    .MuiTypography-body1 {
        font-size: 14px;
        width: 50%;
        @media #{$sp} {
            width: auto;
        }
    }
    .MuiSwitch-track {
        border: 0;
        background-color: #ccc;
    }
}

// FormControlLabel用
.muiSwitch {
    margin-left: 0!important;
    margin-right: 0!important;
    @extend %muiSwitch;
}

.switchList {
    margin-top: 24px;
    margin-bottom: 16px;
    .switchItem {
        display: flex;
        align-items: center;
        flex-direction: row;
        & + .switchItem {
            margin-top: 24px;
        }
        .switchItem__label {
            width: 100%;
            justify-content: flex-end;
            margin: 0;
            @media #{$sp} {
                justify-content: space-between;
            }
        }
        @extend %muiSwitch;
    }
}

// キーワード検索フォーム
.searchWordsForm {
    position: relative;
    .input-control {
        height: 28px;
        min-height: 28px;
        line-height: 28px;
        input {
            font-size: 14px;
            height: 28px;
            min-height: 28px;
            padding: 7px 34px 7px 8px;
            &::placeholder {
                color: #888888;
            }
        }
    }
    button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        border-radius: 0 8px 8px 0;
        cursor: pointer;
        padding: 5px 8px;
        outline: none;
        position: absolute;
        right: 0;
        .MuiSvgIcon-root {
            font-size: 18px !important;
            width: 18px !important;
            color: #333;
        }
    }
    // PCヘッダ用
    &.-header {
        margin-right: 24px;
        width: 240px;
    }
    // SPコンテンツ用
    &.-contents {
        margin: 16px auto 14px;
        width: calc(100% - 32px);
    }
}
