.settingNav {
    @media screen and (min-width: 847px) {
        display: none !important;
    }
    @media screen and (max-width: 846px) {
		display: block !important;
        margin: 24px 0 0;
        &.-salonSetting {
            margin: 0;
        }
    }
    @media screen and (max-width: 480px) {
        margin: 12px 0 0;
    }
    .settingNavHeadinng {
        font-size: 22px;
        margin: 0 16px 16px;
        @media screen and (max-width: 846px) {
            font-size: 20px;
        }
    }
    .settingTab {
		display: flex !important;
		padding-bottom: 1px;
		border-bottom: 1px solid #F2F2F2;
        .settingTab__item {
            text-align: center;
            padding: 6px 8px;
            box-sizing: border-box;
            min-height: 48px;
            width: calc(100% / 3);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            border-bottom: 3px solid transparent;
            opacity: .7;
            @media screen and (max-width: 480px) {
                padding: 6px 2px;
            }
            &.is-active {
                font-weight: bold;
                opacity: 1;
                border-bottom: 3px solid #45B5F5;
            }
        }
        &.-salonSetting {
            flex-wrap: wrap;
            margin-bottom: 40px;
            border-bottom: 0;
            justify-content: center;
            @media (max-width: 640px) {
                margin-bottom: 0;
            }
            .settingTab__item {
                width: auto;
                font-size: 14px;
                width: 33.3%;
            }
        }
    }
}
