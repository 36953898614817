.planSettingNav {
	.planSettingNavHeadinng {
		font-size: 22px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 24px;
	}
}

.planSettingNavList {
	list-style: none;

	.planSettingNavList__item {
		margin-bottom: 16px;

		a,
		span {
			color: #333;
			text-decoration: none;

			&.is-active {
				font-weight: bold;
				color: #45B5F5;
			}
		}
	}
}

.planSettingList {
	.planSettingList__item {
		margin-bottom: 10px;
	}

	.MuiFormControlLabel-root {
		.MuiCheckbox-root {
			padding: 0;
			margin-right: 8px;
		}

		.MuiFormControlLabel-label {
			font-size: 14px;
		}
	}
}

.planSettingListLink {
	display: flex;
	align-items: center;

	a {
		font-weight: bold;
		color: #333;
		display: inline-block;
		cursor: pointer;

		&+a {
			margin-left: 32px;
		}
	}
}