.memberInvitation {
  width: 1130px;
  margin: 0 auto;
  padding: 40px 0 72px;
  color: #333;
  font-family: "Noto Sans JP", sans-serif;
  @media #{$sp} {
    width: 100%;
    padding: 32px 16px 40px;
  }
  .memberInvitation__title {
    font-size: 24px;
    margin-bottom: 16px;
    @media #{$sp} {
      font-size: 20px;
      text-align: center;
    }
  }
  .memberInvitation__description {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 32px;
  }

  .memberInvitationPlanContainer {
    display: flex;
    gap: 48px;
    @media #{$sp} {
      flex-direction: column;
      gap: 24px;
    }
    .memberInvitationPlanWrapper {
      &.-wideContent {
        width: 784px;
        @media #{$sp} {
          width: 100%;
        }
      }
      &.-narrowContent {
        width: 298px;
        @media #{$sp} {
          width: 100%;
        }
      }
    }
  }

  .memberInvitationPlanList {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
    @media #{$sp} {
      margin-bottom: 24px;
    }
    .memberInvitationPlanList__item {
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      padding: 16px 24px;
      @media #{$sp} {
        padding: 16px;
      }
    }
  }
  .memberInvitationBtn {
    font-size: 14px;
    font-weight: bold;
    background: #45b5f5;
    color: #fff;
    width: 392px;
    height: 44px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    line-height: 48px;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    margin: 0 auto;
    @media #{$sp} {
      width: 100%;
    }
  }
  .invitationPlan {
    .invitationPlan__title {
      font-size: 16px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    .invitationPlanContent {
      .invitationPlanContent__title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .invitationPlanContent__text {
        font-size: 14px;
        line-height: 1.7;
      }
    }
  }
  .invitationSalonSummary {
    background: #fff;
    box-shadow: 0px 4px 10px #00000029;
    .invitationSalonSummary__image {
      height: 170px;
      display: flex;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .invitationSalonSummaryContent {
      padding: 16px 24px;
    }
    .invitationSalonSummary__title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .invitationSalonSummary__description {
      font-size: 14px;
    }
    .invitationSalonSummaryOwner {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 16px;
      .invitationSalonSummaryOwner__image {
        width: 37px;
        height: 37px;
        border-radius: 100%;
        object-fit: cover;
        flex-shrink: 0;
      }
      .invitationSalonSummaryOwner__text {
        font-size: 12px;
      }
    }
  }
}

.invitationModal {
  width: 100vw;
  height: 100dvh;
  max-height: none;
  border-radius: 0;
  color: #333;
  box-shadow: none;
  left: 0;
  top: 0;
  transform: none;
  .modal-close {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #2D2D2D;
    font-size: 24px !important;
    width: 24px !important;
  }
  .invitationModal__title {
    font-size: 16px;
    text-align: center;
    padding: 24px;
    border-bottom: 1px solid #ccc;
    @media #{$sp} {
      text-align: left;
    }
  }
  .invitationErrorMessage {
    color: #FF6D6D;
    margin-top: 4px;
    font-size: 13px;
    @media #{$sp} {
      font-size: 12px;
    }
  }
  .invitationModalContent {
    max-width: 1188px;
    margin: 0 auto;
    padding: 32px 24px 56px;
    @media #{$sp} {
      padding: 32px 16px;
    }
    @media only screen and (max-width: 480px) {
      padding: 32px 16px 48px;
    }
    .invitationModalContent___warningText {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #FF6D6D;
      margin-bottom: 40px;
      @media #{$sp} {
        margin-bottom: 32px;
      }
    }
    .invitationModalContent__section {
      & + .invitationModalContent__section {
        border-top: 1px solid #D7DCDF;
        margin-top: 40px;
        padding-top: 40px;
        @media #{$sp} {
          border-top: 0;
          margin-top: 48px;
          padding-top: 0;
        }
      }
    }
    .invitationModalContent__title {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 16px;
    }
    .invitationModalContent__subTitle {
      font-size: 14px;
      margin-bottom: 16px;
    }
    .invitationModalContent__description {
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
  .invitationCategoryList {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
    @media #{$sp} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
    .invitationCategoryList__item {
      display: flex;
    }
  }
  .invitationCategory {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    cursor: pointer;
    &.-selected {
      border: 0;
      background: #45B5F5;
      color: #fff;
      .invitationCategory__radioBtn {
        border-color: #fff;
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background: #fff;
          border-radius: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
      }
      .invitationCategory__capacityLimit {
        color: #fff;
      }
    }
    &.-disabled {
      border: 0;
      background: #EAEAEA;
      color: #888;
      cursor: default;
      .invitationCategory__radioBtn {
        border-color: #888;
      }
      .invitationCategory__capacityLimit {
        color: #888;
      }
    }
    input {
      border: 0;
      display: none;
    }
    .invitationCategory__titleWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
    .invitationCategory__radioBtn {
      position: relative;
      border: 2px solid #313131;
      display: flex;
      width: 16px;
      height: 16px;
      border-radius: 100%;
    }
    .invitationCategory__title {
      font-size: 14px;
    }
    .invitationCategory__permissions {
      font-size: 12px;
      margin-bottom: 16px;
      @media #{$sp} {
        margin-bottom: 10px;
      }
    }
    .invitationCategory__capacityLimit {
      font-size: 12px;
      color: #888;
      margin-top: auto;
    }
  }
  .invitationSubCategoryListWrap {
    margin-top: 24px;
  }
  .invitationSubCategoryList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .invitationSubCategoryList__item {
    }
  }
  .invitationSubCategory {
    max-width: 560px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    min-height: 60px;
    border-radius: 6px;
    border: 1px solid #ccc;
    cursor: pointer;
    &.-selected {
      border: 1px solid #45B5F5;
      background: #45B5F5;
      color: #fff;
      .invitationSubCategory__checkBox {
        position: relative;
        border: 2px solid #fff;
        &:after {
          content: '';
          width: 13px;
          height: 12px;
          display: block;
          background: url(../images/icon_check.svg) no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
      }
      .invitationSubCategory__plan {
        font-weight: bold;
      }
    }
    &.-disabled {
      border: 0;
      background: #EAEAEA;
      color: #888;
      cursor: default;
    }
    input {
      border: 0;
      display: none;
    }
  }
  .invitationSubCategory__checkBox {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #ccc;
  }
  .invitationSubCategory__plan {
    font-size: 14px;
    line-height: 1.5;
  }
  .invitationSubCategory__text {
    margin-right: 16px;
  }
  .invitationFormWrap {
    display: flex;
    gap: 40px;
    @media only screen and (max-width: 820px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 480px) {
      gap: 32px;
    }
  }
  .invitationForm {
    max-width: 529px;
    flex-basis: 100%;
    @media only screen and (max-width: 820px) {
      max-width: none;
    }
    .invitationForm__title {
      font-size: 14px;
      margin-bottom: 12px;
    }
    .invitationForm__description {
      font-size: 12px;
      color: #6E6E6E;
      line-height: 1.5;
    }
  }
  .invitationFormSeparator {
    width: 1px;
    height: auto;
    background: #D7DCDF;
    @media only screen and (max-width: 820px) {
      display: none;
    }
  }
  .invitationInputWrap {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
      margin-top: 16px;
    }
  }
  .invitationInputWithBtnWrap {
    position: relative;
    @media only screen and (max-width: 820px) {
      flex-basis: 100%;
    }
  }
  .invitationInput {
    background: #F7F7F7;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    width: 100%;
    max-width: 374px;
    height: 44px;
    padding: 0 16px;
    @media only screen and (max-width: 820px) {
      max-width: none;
    }
    &::placeholder {
      font-size: 12px;
      color: #999;
    }
    &.-copy {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 64px 0 16px;
    }
  }
  .invitationInputBtn {
    width: 140px;
    height: 44px;
    background: #666;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
    flex-shrink: 0;
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
    &.-submit {
      background: #45B5F5;
    }
    &:disabled {
      background: #ccc;
      cursor: default;
    }
  }
  .invitationInputCopyBtn {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    appearance: none;
    border: 0;
    background: transparent;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
}

.invitationConfirmModal {
  width: 610px;
  color: #333;
  @media #{$sp} {
      width: 92vw;
      padding: 0;
  }
  .modal-close {
    position: relative;
    svg {
      position: absolute;
      right: 16px;
      top: 16px;
      color: #999;
      cursor: pointer;
      @media #{$sp} {
        right: 8px;
        top: 8px;
      }
    }
  }
  .invitationConfirmModal__header {
    padding: 32px 0 0;
    @media #{$sp} {
      border-bottom: 1px solid #E3E3E3;
      padding: 16px 0;
    }
  }
  .invitationConfirmModal__title {
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1.3;
    text-align: center;
    @media #{$sp} {
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 0;
    }
    &.-error {
      color: #ff6d6d;
    }
  }
  .invitationConfirmModal__description {
    font-size: 14px;
    line-height: 1.7;
    text-align: center;
    @media #{$sp} {
      font-size: 12px;
      line-height: 1.5;
      white-space: pre-wrap;
    }
  }
  .invitationConfirmModalSalonSummary {
    display: flex;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 16px;
    margin: 24px 56px 32px;
    gap: 16px;
    @media #{$sp} {
      border: 0;
      padding: 16px 16px 24px;
      margin: 0;
      flex-direction: column;
    }
    .invitationConfirmModalSalonSummary__image {
      width: 180px;
      overflow: hidden;
      flex-shrink: 0;
      @media #{$sp} {
        width: 196px;
        margin: 0 auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 7/4;
      }
    }
    .invitationConfirmModalSalonSummaryContent {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .invitationConfirmModalSalonSummary__title {
      font-size: 14px;
    }
    .invitationConfirmModalSalonSummaryOwner {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 4px;
      @media #{$sp} {
        margin-top: 12px;
      }
      .invitationConfirmModalSalonSummaryOwner__image {
        width: 37px;
        height: 37px;
        border-radius: 100%;
        object-fit: cover;
        flex-shrink: 0;
      }
      .invitationConfirmModalSalonSummaryOwner__text {
        font-size: 12px;
      }
    }
  }
}

.invitationCategoryDetailModal {
  width: 100vw;
  height: 100dvh;
  max-height: none;
  border-radius: 0;
  color: #333;
  box-shadow: none;
  left: 0;
  top: 0;
  transform: none;
  .modal-close {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #2D2D2D;
    font-size: 24px !important;
    width: 24px !important;
  }
  .invitationCategoryDetailModal__title {
    font-size: 16px;
    text-align: center;
    padding: 24px;
    border-bottom: 1px solid #ccc;
  }
  .invitationCategoryDetailModalContent {
    max-width: 1008px;
    margin: 0 auto;
    padding: 32px 24px 56px;
    @media #{$sp} {
      padding: 32px 16px;
    }
    @media only screen and (max-width: 480px) {
      padding: 32px 16px 48px;
    }
    .invitationCategoryDetailModalContent__description {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }
}

.invitationCategoryDetail {
	width: 100%;
	overflow: hidden;
  font-family: "Noto Sans JP", sans-serif;
  border-collapse: collapse;
  @media #{$sp} {
    margin-bottom: 16px;
  }
	th, tr, td {
    white-space: nowrap;
	}
  td, th {
    border: 1px solid #999999;
    padding: 0;
    height: 40px;
    width: 20%;
    @media #{$sp} {
      width: auto;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:not(:first-child) {
      width: 16%;
      @media #{$sp} {
        width: auto;
      }
    }
  }
	tr {
		@media #{$sp} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
		}
	}
	tr, td {
    width: auto;
		@media #{$sp} {

		}
	}
  .headerCell {
    background: #F2F2F2;
		@media #{$sp} {
      font-size: 11px;
      border-right: 0;
      &:last-child {
        border-right: 1px solid #999999;
      }
		}
    &.-root {
      @media #{$sp} {
        display: none;
      }
    }
    &.-firstRow {
      background: #C6E9FF;
      font-weight: normal;
    }
    &.-firstColumn {
      font-size: 12px;
      text-align: left;
      padding: 0 16px;
      @media #{$sp} {
        display: flex;
        justify-content: flex-start;
        padding: 0 8px;
        border-right: 1px solid #999999;
        grid-column-start: 1;
        grid-column-end: 6;
        border-top: 0;
        border-bottom: 0;
      }
    }
    .headerCell__inner {
      display: flex;
      flex-direction: column;
      line-height: 1;
      @media #{$sp} {
        flex-direction: row;
        align-items: center;
        gap: 8px;
        line-height: 1.3;
      }
    }
    .noticeMessage {
      font-size: 10px;
      font-weight: normal;
      margin-top: 6px;
      @media #{$sp} {
        margin-top: 0;
      }
    }
  }
  .dataCell {
    background: #fff;
		@media #{$sp} {
      font-size: 12px;
      border-right: 0;
      &:last-child {
        border-right: 1px solid #999999;
      }
		}
    .dataCell__inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.invitationCategoryDetailNoticeList {
  @media #{$pc} {
    display: none;
  }
  .invitationCategoryDetailNoticeList__item {
    font-size: 12px;
    line-height: 1;
    span {
      margin-right: 8px;
    }
    & + .invitationCategoryDetailNoticeList__item {
      margin-top: 8px;
    }
  }
}
